<template>
  <DefaultLayout v-if="tokens && !$route.meta.loginPage">
    <router-view :loading="loading" />
  </DefaultLayout>
  <router-view v-else-if="$route.meta.loginPage || $route.meta.termsPage" />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DefaultLayout from "./layouts/DefaultLayout";
import store from "@/store/stores";
import axios from "axios";

export default {
  name: "App",
  metaInfo: {
    title: "VivoLang",
    titleTemplate: "%s - VivoLang"
  },
  data() {
    return {
      interval: null
    };
  },
  mounted() {
    window.addEventListener("storage", this.logoutSync);

    const storageIntroData = window.localStorage.getItem("introJs");
    if (storageIntroData) {
      this.setIntroInitialData(JSON.parse(storageIntroData));
    }
  },
  methods: {
    async getData() {
      const path = await this.getAvailablePaths({ id: this.id });
      let pathID = this.getPathID;

      if (!this.getPathID) {
        if (path && path.length === 0) {
          this.setLoading({ status: false });
          throw new Error("No paths found for the user");
        }
        pathID = await this.sendCurrentPathId({ currentPathId: path?.[0]?.id });
      }
      await this.getPathLessons({ pathId: pathID });

      this.$nextTick(async () => {
        this.setLoading({ status: false });
      });
    },
    setRefreshToken() {
      this.interval = setInterval(async () => {
        await axios
          .post(process.env.VUE_APP_AUTH_URL + "/oauth/token", {
            grant_type: "refresh_token",
            refresh_token: this.tokens.refresh_token,
            client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
            scope: ""
          })
          .then(data => {
            store.commit("userStore/setTokens", data.data);
          });
      }, this.tokens.expires_in * 1000 - 30000);
    },
    logoutSync(event) {
      if (event.key === "logout") {
        window.location.replace(process.env.VUE_APP_AUTH_URL + "/login");
      }
    },
    ...mapActions("userStore", ["getUserDetails", "sendCurrentPathId"]),
    ...mapMutations("userStore", ["setIntroInitialData"]),
    ...mapActions("pathsStore", ["getAvailablePaths", "getPathLessons"]),
    ...mapMutations("pathsStore", ["setLoading"])
  },
  computed: {
    ...mapState("userStore", ["id", "tokens"]),
    ...mapGetters("userStore", ["getPathID"]),
    ...mapState("pathsStore", ["loading"])
  },
  watch: {
    async $route(to, from) {
      if ((!from.name || from.meta.loginPage) && this.tokens && !to.meta.loginPage) {
        await store.dispatch("userStore/getUserDetails");
        await this.getData();
      }

      if (!this.interval && this.tokens) {
        this.setRefreshToken();
      }

      if (to.name !== from.name) {
        introJs().exit();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.logoutSync);
    clearInterval(this.interval);
  },
  components: {
    DefaultLayout
  }
};
</script>

<style lang="scss">
@import "../src/scss/main";
</style>
