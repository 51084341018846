<template>
  <div class="audioPlayer">
    <div class="audio-player">
      <div v-if="loaded" class="audio-player__actions">
        <div class="audio-player__buttons">
          <button type="button" @click="playAudio" class="audio-player__play">
            <inline-svg
              :src="require(`../assets/icons/${currentlyPlaying ? 'pause' : 'play'}.svg`)"
              fill="currentColor"
            ></inline-svg>
          </button>
          <button v-if="stopButton" type="button" @click="resetPlaylist" class="audio-player__stop">
            <inline-svg :src="require(`../assets/icons/stop.svg`)" fill="currentColor"></inline-svg>
          </button>
        </div>
        <div
          v-if="!disableTimeLine"
          ref="timeline"
          class="audio-player__timeline"
          :class="{
            'is-disabled': disableTimelineClick
          }"
          @click="changeTrackPosition"
        >
          <img src="/assets/images/timeline.svg" alt="timeline" />
          <img
            class="is-filled"
            src="/assets/images/timeline_filled.svg"
            :style="{ width: currentProgressBarPercentage + '%' }"
            alt="timeline"
          />
        </div>
        <div
          v-if="showTimer"
          class="audio-player__time"
          :class="{
            'is-disabled': disableTimeLine
          }"
        >
          {{ fancyTimeFormat(timeToEnd()) }}
        </div>
      </div>
      <Loading v-else></Loading>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Player",

  data() {
    return {
      audio: null,
      currentlyPlaying: false,
      currentPlaylistTime: 0,
      trackPositionTimer: 0,
      trackDuration: 0,
      currentProgressBarPercentage: 0,
      currentSong: 0,
      musicPlaylist: [],
      playlistDuration: 0,
      loaded: false
    };
  },

  props: {
    audioFiles: Array,
    stopButton: Boolean,
    showTimer: {
      type: Boolean,
      default: true
    },
    disableTimelineClick: {
      type: Boolean,
      default: false
    },
    disableTimeLine: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.modifyAudioArray();
    this.preloadAudio();
  },
  methods: {
    modifyAudioArray() {
      const audios = this.audioFiles.map(url => ({ url }));
      this.musicPlaylist = [...audios];
    },

    fancyTimeFormat(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },

    changeTrackPosition(event) {
      const timeline = this.$refs.timeline;
      const percentageWidth = timeline.offsetWidth;
      const clickedTime = Math.round((event.offsetX / (percentageWidth / this.playlistDuration)) * 100) / 100;
      const playAudio = _.findIndex(this.musicPlaylist, track => {
        return track.min <= clickedTime && track.max > clickedTime;
      });

      this.audio.currentTime = 0.0;
      this.changeSong(playAudio);
      this.updateTimeline();
    },

    timeToEnd() {
      return Math.ceil(this.playlistDuration - this.getCurrentPlaylistDuration()) || 0;
    },

    preloadAudio() {
      let audioDuration = 0;
      this.playlistDuration = 0;

      _.each(this.musicPlaylist, audio => {
        audio.htmlElemnt = new Audio(audio.url);
      });

      return Promise.all(
        _.map(this.musicPlaylist, track => {
          track.htmlElemnt.load();
          return new Promise(resolve => track.htmlElemnt.addEventListener("loadeddata", resolve));
        })
      ).then(() => {
        _.each(this.musicPlaylist, audio => {
          audio.duration = audio.htmlElemnt.duration;
          this.playlistDuration += audio.duration;
          audio.min = audioDuration;
          audioDuration = audioDuration + audio.htmlElemnt.duration;
          audio.max = audioDuration;
        });

        this.changeSong();
        this.loaded = true;
      });
    },

    getFinishedPlaylistDuration(currentAudioIndex) {
      return _.reduce(
        this.musicPlaylist,
        (result, item, index) => {
          if (index < currentAudioIndex) {
            return result + item.duration;
          }

          return result;
        },
        0
      );
    },

    getCurrentPlaylistDuration() {
      return Math.round((this.getFinishedPlaylistDuration(this.currentSong) + this.audio.currentTime) * 100) / 100;
    },

    changeSong(index) {
      let wasPlaying = this.currentlyPlaying;

      if (index !== undefined) {
        this.stopAudio();
        this.currentSong = index;
      }

      this.audio = this.musicPlaylist[this.currentSong].htmlElemnt;
      this.trackDuration = this.audio.duration;
      this.audio.addEventListener("ended", this.handleEnded);

      if (wasPlaying) {
        this.playAudio();
      } else {
        this.stopAudio();
      }

      this.$emit("changeSong", this.currentSong);
    },

    resetPlaylist() {
      this.audio.currentTime = 0.0;
      this.stopAudio();
      this.changeSong(0);
      this.updateTimeline();
    },

    playAudio() {
      if (this.currentSong === this.musicPlaylist.length) {
        this.currentSong = 0;
        this.changeSong();
      }

      if (!this.currentlyPlaying) {
        this.getCurrentTimeEverySecond(true);
        this.currentlyPlaying = true;
        setTimeout(() => {
          this.audio.play();
          this.$emit("startPlay");
        }, 100);
      } else {
        this.stopAudio();
      }
    },

    stopAudio() {
      if (!this.audio) return;
      this.audio.pause();
      this.currentlyPlaying = false;
      this.pausedMusic();
    },

    handleEnded() {
      this.currentlyPlaying = false;
      this.audio.currentTime = 0.0;

      if (this.currentSong + 1 === this.musicPlaylist.length) {
        // end of playlist
        this.changeSong(0);
        this.updateTimeline();
        this.$emit("endPlaylist");
      } else {
        //next song
        this.currentSong++;
        this.changeSong();
        this.playAudio();
      }
    },

    stopPlay() {
      this.currentlyPlaying = false;
      this.audio.currentTime = 0;

      this.changeSong(0);
      this.updateTimeline();
    },

    getCurrentTimeEverySecond() {
      this.trackPositionTimer = setTimeout(() => {
        this.updateTimeline();
        this.getCurrentTimeEverySecond(true);
      }, 150);
    },

    updateTimeline() {
      this.currentPlaylistTime = this.getCurrentPlaylistDuration();
      this.currentProgressBarPercentage =
        (this.currentPlaylistTime / this.playlistDuration) * 100 > 100
          ? 100
          : (this.currentPlaylistTime / this.playlistDuration) * 100;
    },

    pausedMusic() {
      clearTimeout(this.trackPositionTimer);
    }
  },
  computed: {
    ...mapState("lessonStore", ["currentModule"]),
    ...mapGetters("speakingStore", ["questionAudio"])
  },

  beforeDestroy() {
    if (this.audio) {
      this.audio.removeEventListener("ended", this.handleEnded);
      this.audio.removeEventListener("loadedmetadata", this.handleEnded);
      this.stopPlay();
      clearTimeout(this.trackPositionTimer);
    }
  },

  watch: {
    audioFiles() {
      if (this.currentModule !== "speaking") {
        this.stopAudio();
        this.modifyAudioArray();
        this.preloadAudio().then(() => {
          this.updateTimeline();
        });
      }
    },
    questionAudio() {
      this.stopAudio();
      this.modifyAudioArray();
      this.preloadAudio().then(() => {
        this.updateTimeline();
      });
    },
    loaded: {
      handler(newVal) {
        if (newVal) {
          this.$emit("playerLoaded");
        }
      },
      immediate: true
    }
  }
};
</script>
