import router from "../../../router";

export const mapModules = [
  {
    key: "podcast",
    icon: "headphones",
    iconSize: 22,
    text: "Podcast",
    route: { name: "Lesson", params: { module: "podcast" } }
  },
  {
    key: "key_phrases",
    icon: "list",
    iconSize: 21,
    text: "Key Phrases",
    route: { name: "Lesson", params: { module: "key_phrases" } }
  },
  {
    key: "vocabulary",
    icon: "message",
    iconSize: 22,
    text: "Vocabulary",
    route: { name: "Lesson", params: { module: "vocabulary" } }
  },
  {
    key: "speaking",
    icon: "micro",
    iconSize: 24,
    text: "Speaking",
    route: { name: "Lesson", params: { module: "speaking" } }
  },
  {
    key: "summary",
    icon: "refresh",
    iconSize: 30,
    text: "Summary",
    route: { name: "Lesson", params: { module: "summary" } }
  }
];

export const modulesList = mapModules.map(module => module.route.params.module);

export function changeRoute(module) {
  if (typeof module === "object") {
    return router.push(module);
  }
  return router.push({
    name: "Lesson",
    params: {
      module
    }
  });
}

export function getModule(lessonModules, currentModule, prev, isLastLesson) {
  const currentModuleIndex = lessonModules.indexOf(currentModule);
  const moduleIndex = prev ? currentModuleIndex - 1 : currentModuleIndex + 1;
  const availableModules = mapModules.filter(module => lessonModules.includes(module.key) || module.key === "summary");

  if (currentModuleIndex === -1 && !prev && isLastLesson) {
    return {
      text: "Go to Home",
      route: { name: "Home" }
    };
  }

  if (currentModuleIndex === -1 && !prev) {
    return {
      text: "Next lesson"
    };
  }

  if (moduleIndex === -1) {
    return {
      text: "Go to Home",
      route: { name: "Home" }
    };
  }

  if (currentModule === "summary") {
    return availableModules[availableModules.length - 2];
  }

  if (moduleIndex >= 0) {
    return availableModules[moduleIndex];
  }
}
