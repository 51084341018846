<template>
  <div class="popup">
    <div class="h2 popup__title">
      {{ $t("share.header") }}
    </div>
    <div class="popup__desc">
      <span v-for="network in networks" :key="network.name">
        <ShareNetwork
          v-if="network.show"
          class="popup__share-icon"
          :network="network.name"
          :url="demoSessionUrl"
          :title="$t('share.title')"
          :description="$t('share.message')"
          hashtags="vivolang"
        >
          <font-awesome-icon :icon="network.icon" size="2xl" :class="`popup__${network.name}`" />
        </ShareNetwork>
      </span>
    </div>

    <div class="popup__copy-link">
      <div class="h3 popup__copy-title">Or copy to clipboard</div>
      <div class="popup__item">
        <InputButton @click="copyLink" small outline :text="$t('share.copy-to-clipboard')" action="button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharePopup",
  computed: {
    demoSessionUrl() {
      return process.env.VUE_APP_API_URL + "/start-demo-session";
    },

    isMobile() {
      return navigator.maxTouchPoints > 1;
    },

    networks() {
      return [
        { name: "messenger", icon: "fa-brands fa-facebook-messenger", show: this.isMobile },
        { name: "linkedin", icon: "fa-brands fa-linkedin", show: true },
        { name: "whatsapp", icon: "fa-brands fa-whatsapp", show: true }
      ];
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.demoSessionUrl).then(() => {
        this.$toast.success(this.$t("share.copy-success"));
      });
    }
  }
};
</script>
