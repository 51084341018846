<template>
  <div class="container-fluid h-100">
    <div class="row columns-16 h-100">
      <Sidebar
        @mobileMenuStateChange="changeMainContentVisibility($event)"
        @changeMobileMenuState="changeMainContentVisibility($event)"
      />
      <div class="center-container main-area" :class="{ 'center-container--hidden': mobileMenuOpened }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  data() {
    return {
      mobileMenuOpened: false
    };
  },
  methods: {
    changeMainContentVisibility(e) {
      this.mobileMenuOpened = e;
    }
  }
};
</script>
