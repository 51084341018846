import axios from "axios";
import store from "../store/stores.js";

const requestTimeoutInterceptor = config => {
  if (config.timeout === undefined || config.timeout === 0) {
    return config;
  }
  const source = axios.CancelToken.source();

  setTimeout(() => {
    source.cancel(`Cancelled request. Took longer than ${config.timeout}ms to get complete response.`);
  }, config.timeout);

  if (config.cancelToken) {
    config.cancelToken.promise.then(cancel => {
      source.cancel(cancel.message);
    });
  }

  return { ...config, cancelToken: source.token };
};
const requestAcceptLanguageInceptor = config => {
  config.headers["Accept-Language"] = store.state.userStore.userLanguage;
  config.headers["Authorization"] = "Bearer " + store.state.userStore.tokens?.access_token;
  return config;
};

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/`,
  withCredentials: true,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

axiosInstance.interceptors.request.use(requestAcceptLanguageInceptor);
axiosInstance.interceptors.request.use(requestTimeoutInterceptor);

axiosInstance.interceptors.response.use(
  response => {
    // all 2xx/3xx responses will end here
    return response;
  },
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject("Request canceled");
    }

    const errorCode = error.response?.status;

    if (error.response) {
      const responseData = error.response.data;
      const errors = responseData.errors;

      if (!errors && !responseData.messages) {
        if (errorCode === 401) {
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
