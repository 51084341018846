<template>
  <div class="dashboard-right-lesson" v-if="lesson">
    <div @click="startOrResume" class="h2 dashboard-right-lesson__title">
      {{ getBoxTitle }}
      <inline-svg
        :src="require(`../assets/icons/arrow.svg`)"
        fill="currentColor"
        aria-label="arrow"
        width="9"
      ></inline-svg>
    </div>

    <div class="row columns-4">
      <div class="dashboard-right-lesson__lesson">
        <LessonCard
          :title="lesson.title"
          :course="path.title"
          :image-src="lesson.featureImageUrl"
          :key-phrases-count="lesson.keyPhrases"
          :questions-count="lesson.questions"
          :progress="getAttemptProgress"
          @click="startOrResume"
          :attempt="lesson.attempt"
          :current-lesson="null"
          :last-completed-lesson="null"
          :level="lesson.difficulty"
          :keyPhrasesActive="isKeyPhrasesActive(lesson)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "DashboardRight",

  props: {
    lesson: {
      type: Object
    },
    path: {
      type: Object,
      required: true
    },
    isStart: {
      type: Boolean,
      required: true
    },
    isFirst: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    getAttemptProgress() {
      return this.lesson ? this.lesson.attempt.progress : null;
    },

    getBoxTitle() {
      if (this.isStart) {
        if (this.isFirst) {
          return this.$t("start-first-lesson-right");
        }

        return this.$t("start-lesson-right");
      }

      return this.$t("continue-lesson-right");
    }
  },

  methods: {
    startOrResume() {
      if (this.isStart) {
        this.startNewLesson(this.lesson);
      } else if (this.lesson.attempt.status === "finished") {
        this.continueLesson({ lesson: this.lesson });
      } else {
        this.resumeStartedLesson(this.lesson);
      }
    },

    startNewLesson(lesson) {
      if (this.path.attempt?.uuid) {
        this.startLesson(lesson, this.path.attempt.uuid);
      } else {
        this.newPathAttempt({ path: this.path }).then(uuid => {
          this.setPathAttemptProperty({ key: "uuid", value: uuid });
          this.startLesson(lesson, uuid);
        });
      }
    },

    startLesson(lesson, path) {
      this.newLesson({
        lesson: lesson,
        pathAttemptUuid: path
      });
    },

    resumeStartedLesson(lesson) {
      this.resumeLesson({ lesson });
    },

    isKeyPhrasesActive(lesson) {
      return lesson.module_states?.key_phrases;
    },

    ...mapActions("lessonStore", ["newLesson", "continueLesson", "redirectToLesson", "resumeLesson", "newPathAttempt"]),
    ...mapMutations("pathsStore", ["setPathAttemptProperty"])
  }
};
</script>
