<template>
  <div>
    <vue-scroll
      ref="vs"
      :ops="{
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        },
        vuescroll: {
          detectResize: true,
          sizeStrategy: 'percent'
        },
        rail: { gutterOfSide: '0' }
      }"
    >
      <div class="speaking-chat__wrapper">
        <div class="speaking-chat__inner-wrapper">
          <SpeakingQuestion @playerLoaded="$emit('playerLoaded')" />
          <div class="speaking-answer__wrapper">
            <SpeakingAnswerRecorder />
            <SpeakingAnswer />
          </div>
          <fade-transition>
            <SpeakingRecordConfirmation
              v-show="audio && !currentQuestion.completed && isAnswerRecorded"
              ref="speakingRecordConfirmation"
            />
          </fade-transition>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import SpeakingQuestion from "./SpeakingQuestion.vue";
import SpeakingAnswer from "./SpeakingAnswer.vue";
import SpeakingAnswerRecorder from "./SpeakingAnswerRecorder.vue";
import SpeakingRecordConfirmation from "./SpeakingRecordConfirmation.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SpeakingChat",
  components: { SpeakingRecordConfirmation, SpeakingAnswerRecorder, SpeakingQuestion, SpeakingAnswer },
  computed: {
    ...mapGetters("speakingStore", ["currentQuestion"]),
    ...mapState("speakingStore", ["audio", "isAnswerRecorded"])
  },
  watch: {
    isAnswerRecorded: {
      handler(newVal) {
        if (newVal) {
          const animationTime = 200;
          setTimeout(() => {
            this.$emit("recordConfirmationRendered");
          }, animationTime);
        }
      },
      immediate: true
    }
  }
};
</script>
