<template>
  <div v-bind="$attrs" class="global-loader global-loader--primary">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>
