import client from "../../helpers/client";
import Vue from "vue";

const pathsStore = {
  namespaced: true,

  state() {
    return {
      paths: null,
      currentPath: null,
      loading: true
    };
  },

  getters: {
    amountOfLessons(state) {
      return state.currentPath?.lessons.length ?? 0;
    }
  },

  mutations: {
    setLoading(state, { status }) {
      state.loading = status;
    },
    setPaths(state, { paths }) {
      state.paths = paths;
    },

    setCurrentPath(state, { currentPath }) {
      state.currentPath = currentPath;
    },

    setPathAttemptProperty(state, { key, value }) {
      if (!state.currentPath.attempt) {
        state.currentPath.attempt = {};
      }
      Vue.set(state.currentPath.attempt, key, value);
    },

    clearPathsData(state) {
      state.paths = null;
      state.currentPath = null;
    }
  },
  actions: {
    async getAvailablePaths({ commit }, { id }) {
      return await client.get(`api/users/${id}/paths`).then(response => {
        commit("setPaths", { paths: response.data.data });
        return response.data.data;
      });
    },

    async getPathLessons({ commit }, { pathId }) {
      return await client.get(`api/paths/${pathId}`).then(response => {
        commit("setCurrentPath", { currentPath: response.data.data });
        return response.data.data;
      });
    }
  }
};

export default pathsStore;
