<template>
  <div v-if="visible" class="summary-button__container" @click="changePath">
    <span class="summary-button__iconWrapper"
      ><Icon size="small" :name="name" icon-color-name="secondary-color" background-color-name="primary-color"
    /></span>
    <span class="summary-button__label">{{ label }}</span>
    <span class="summary-button__arrow">
      <inline-svg :src="require(`../../assets/icons/arrow.svg`)" fill="currentColor" />
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SummaryButton",
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState("lessonStore", ["lesson"])
  },
  methods: {
    async changePath() {
      switch (this.action) {
        case "vocabulary":
        case "speaking":
          await this.changeLessonModule({ module: this.action });
          break;
        case "dashboard":
          await this.$router.push({ name: "Home" });
          break;
        case "lesson":
          await this.newLesson({ lesson: this.lesson, pathAttemptUuid: this.lesson.attempt.pathAttemptUuid });
          this.$router.go();
          break;
        case "sign up":
          await this.$router.push({ name: "Registration" });
          break;
      }
    },
    ...mapActions("lessonStore", ["changeLessonModule", "newLesson"])
  }
};
</script>
