import client from "../helpers/client";
import store from "@/store/stores";
import { mapState } from "vuex";

const introJsMixin = {
  beforeDestroy() {
    this.setTours([]);
  },
  methods: {
    startIntro({ module }) {
      this.setIntroJs({ key: module, status: false });
      introJs()
        .setOptions({
          exitOnOverlayClick: false,
          steps: store.state.userStore.tours.find(item => item.key === module).data.steps,
          showBullets: false
        })
        .onbeforeexit(async () => {
          if (module === "speaking") {
            await client.post(`api/user/${this.id}/mark-intro`, {
              markIntro: true
            });
          }
        })
        .start();
    },
    showIntroFirstTime(module) {
      if (this.introJs[module]) {
        this.startIntro({ module });
      }
    }
  },
  computed: {
    isTours() {
      return store.state.userStore.tours.length > 0;
    },
    getFirstTourKey() {
      return store.state.userStore.tours.length > 0 ? store.state.userStore.tours[0].key : null;
    },
    ...mapState("userStore", ["id"])
  }
};

export default introJsMixin;
