<template>
  <div class="split-to-translate__container">
    <div class="split-to-translate__items">
      <translation-item
        class="split-to-translate__item"
        :key="`${sentence}-${index}`"
        v-for="(sentence, index) in sentences"
        :phrase="sentence"
      ></translation-item>
    </div>
  </div>
</template>

<script>
import TranslationItem from "./TranslationItem";
export default {
  name: "SplitComponent",
  components: { TranslationItem },
  props: {
    text: String
  },
  computed: {
    sentences() {
      const sentencesArr = [];
      const div = document.createElement("div");
      div.innerHTML = this.text;
      div.childNodes.forEach(childNode => {
        if (childNode instanceof HTMLElement) {
          sentencesArr.push(childNode.outerHTML.trim());
          return;
        }
        sentencesArr.push(...childNode.textContent.trim().split(" "));
      });
      return sentencesArr;
    }
  }
};
</script>
