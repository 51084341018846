<template>
  <div class="audioPlayer">
    <div class="audio-player">
      <div class="audio-player__actions">
        <div class="audio-player__buttons">
          <button type="button" class="audio-player__play audio-player__play--disabled">
            <inline-svg :src="require('@/assets/icons/play.svg')" fill="currentColor"></inline-svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpeakingPlayerPlaceholder"
};
</script>
