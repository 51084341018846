<template>
  <div class="video-player">
    <div class="video-player__wrapper">
      <vimeo-player
        v-if="getVideoProvider"
        class="vimeo"
        :options="{ responsive: true, color: '89DFE5' }"
        :video-url="url"
        @ready="onReady"
      />
      <youtube v-else :video-id="getVideoId" @ready="onReady" :nocookie="true" />
      <loading v-if="loading" />
    </div>
  </div>
</template>

<script>
export default {
  name: "videoPlayer",
  props: {
    url: String
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    getVideoId() {
      return this.$youtube.getIdFromUrl(this.url);
    },
    getVideoProvider() {
      return this.url.includes("vimeo");
    }
  },
  components: {},
  methods: {
    onReady() {
      this.loading = false;
      this.$emit("playerLoaded");
    }
  }
};
</script>
