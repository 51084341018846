var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"simple-icon",class:[
    {
      'simple-icon--big': _vm.size === 'big',
      'simple-icon--small': _vm.size === 'small',
      'simple-icon--medium': _vm.size === 'medium',
      'simple-icon--lesson-nav': _vm.size === 'lesson-nav',
      'simple-icon--lesson-tab': _vm.size === 'lesson-tab'
    }
  ]},[_c('span',{staticClass:"simple-icon__wrapper",class:[
      {
        'is-outline': _vm.type === 'outline',
        'is-shadow': _vm.type === 'shadow'
      }
    ],style:({
      color: _vm.BackgroundColorName ? ("var(--" + _vm.BackgroundColorName + ")") : 'transparent'
    })},[_c('span',{staticClass:"simple-icon__inner",style:({
        color: _vm.IconColorName ? ("var(--" + _vm.IconColorName + ")") : 'transparent',
        'background-color': _vm.BackgroundColorName ? ("var(--" + _vm.BackgroundColorName + ")") : 'transparent'
      })},[_c('inline-svg',{attrs:{"src":require(("../assets/icons/" + _vm.name + ".svg")),"fill":"currentColor","aria-label":_vm.name,"width":_vm.width,"height":_vm.height}})],1)]),(_vm.text)?_c('span',{staticClass:"simple-icon__text"},[_vm._v(_vm._s(_vm.$t(_vm.text)))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }