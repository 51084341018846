<template>
  <div class="speaking-chat__item speaking-chat__item--recorder">
    <div class="placeholder-button">
      <div v-if="audio && audio.src">
        <player :audio-files="[audio.src]" disable-time-line ref="audio-player" />
      </div>
      <SpeakingPlayerPlaceholder v-else />
    </div>

    <SpeakingButton
      @click="handleRecorderButtonClick"
      :text="speakingButtonText"
      :iconName="speakingButtonIcon"
      iconPosition="left"
      class="primary-button primary-button__action"
      :disabled="!recordingIsListened && !showQuestionHint"
    />
  </div>
</template>

<script>
import store from "@/store/stores";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SpeakingButton from "./SpeakingButton.vue";
import SpeakingPlayerPlaceholder from "./SpeakingPlayerPlaceholder.vue";

export default {
  name: "SpeakingAnswerRecorder",
  components: { SpeakingPlayerPlaceholder, SpeakingButton },
  computed: {
    isAnswer() {
      return this.isAnswerRecorded;
    },
    speakingButtonText() {
      if (this.isAnswer && !this.isAnswerRecording) {
        return this.$t("speaking-record-again");
      } else if (this.isAnswerRecording) {
        return this.$t("speaking-recording");
      } else {
        return this.$t("speaking-record");
      }
    },
    speakingButtonIcon() {
      if (this.isAnswerRecording) {
        return "pause-recording";
      } else {
        return "record";
      }
    },
    ...mapState("speakingStore", [
      "isAnswerRecording",
      "isAnswerRecorded",
      "audio",
      "recordingIsListened",
      "showQuestionHint",
      "questionIndex"
    ]),
    ...mapGetters("speakingStore", ["currentQuestion"])
  },
  methods: {
    stopRecording() {
      this.stopRecordAudio();
      this.setShowQuestionHint({ hint: true });
      this.setShowAnswerHint({ hint: true });
    },
    handleRecorderButtonClick() {
      store.commit("speakingStore/setAudio", { audio: false });
      this.isAnswerRecording
        ? this.stopRecording()
        : this.recordAudio({ toast: this.$toast, message: this.$t("microphone-required") });
    },
    ...mapMutations("speakingStore", ["setShowAnswerHint", "setShowQuestionHint"]),
    ...mapActions("speakingStore", ["recordAudio", "stopRecordAudio"])
  },
  watch: {
    questionIndex() {
      store.commit("speakingStore/setAudio", { audio: false });
    }
  }
};
</script>

<style scoped lang="scss">
.placeholder-button,
.placeholder-button .audioPlayer {
  width: 8.3333333333vh;
  height: 8.3333333333vh;
}
</style>
