var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.keyPhrases)?_c('div',{staticClass:"lesson-module",on:{"click":function($event){_vm.isIntroJs && _vm.introJs.keyPhrases ? _vm.$intro().exit() : ''}}},[_c('Portal',{attrs:{"to":"lesson-description"}},[_vm._v(" "+_vm._s(_vm.$t("key-phrases.desc"))+" ")]),_c('div',{staticClass:"row columns-14 key-phrases"},[_c('div',{staticClass:"key-phrases__players"},[_c('div',{staticClass:"key-phrases__player"},[_c('Player',{ref:"phrase",attrs:{"disableTimelineClick":true,"stop-button":false,"audio-files":_vm.getAudio('phrase', _vm.selectedKeyPhrase),"showTimer":false},on:{"startPlay":function($event){return _vm.stopPlay('usage')},"endPlaylist":function($event){return _vm.endPlaylist('phrase')},"playerLoaded":_vm.onPlayerLoaded}}),_c('div',{staticClass:"key-phrases__desc"},[_c('translate-full-phrase',{attrs:{"text":_vm.getAudioText('phrase', _vm.selectedKeyPhrase)}})],1)],1),_c('div',{staticClass:"key-phrases__player"},[_c('Player',{ref:"usage",attrs:{"disableTimelineClick":true,"stop-button":false,"audio-files":_vm.getAudio('usage', _vm.selectedKeyPhrase),"showTimer":false},on:{"startPlay":function($event){return _vm.stopPlay('phrase')},"endPlaylist":function($event){return _vm.endPlaylist('usage')},"playerLoaded":_vm.onPlayerLoaded}}),_c('div',{ref:"examplePhraseUsage",staticClass:"key-phrases__desc"},[_c('translate-full-phrase',{attrs:{"text":_vm.getAudioText('usage', _vm.selectedKeyPhrase)}})],1)],1)]),_c('div',{ref:"phrasesList",staticClass:"key-phrases__vue-scroll"},[_c('vue-scroll',{attrs:{"ops":{
          scrollPanel: {
            scrollingX: false,
            scrollingY: true
          },
          vuescroll: {
            detectResize: true,
            sizeStrategy: 'percent'
          },

          rail: { gutterOfSide: '0' }
        }}},[_c('div',{staticClass:"key-phrases-list"},_vm._l((_vm.keyPhrases),function(keyPhrase,index){return _c('div',{key:keyPhrase.id,staticClass:"key-phrases-list__item round-button",class:{
              'is-current': index === _vm.selectedKeyPhrase
            },on:{"click":function($event){return _vm.setKeyPhrase(index, keyPhrase.id)}}},[_vm._v(" "+_vm._s(keyPhrase.name)+" ")])}),0)])],1),_c('Portal',{attrs:{"to":"lesson-nav-prev"}},[_c('div',{staticClass:"lesson__nav lesson__nav--prev"},[_c('button',{staticClass:"lesson__button",on:{"click":_vm.handlePrevBtnClick}},[_c('Icon',{attrs:{"size":"lesson-nav","name":_vm.getNavIconName,"icon-color-name":"secondary-color"}})],1)])]),_c('Portal',{attrs:{"to":"lesson-nav-next"}},[_c('div',{staticClass:"lesson__nav lesson__nav--next"},[_c('button',{staticClass:"lesson__button",on:{"click":_vm.handleNextBtnClick}},[_c('Icon',{attrs:{"size":"lesson-nav","name":_vm.getNavIconName,"icon-color-name":"secondary-color"}})],1)])]),_c('Portal',{attrs:{"to":"mobile-nav-slot"}},[_c('div',{staticClass:"lesson__nav lesson__nav--skip"},[_c('button',{staticClass:"lesson__button",on:{"click":_vm.skipAll}},[_vm._v(" Skip all ")])])])],1),_c('Portal',{attrs:{"to":"module-progress"}},[_c('ProgressBar',{attrs:{"currentProgress":_vm.selectedKeyPhrase,"totalProgressAmount":_vm.keyPhrases.length}})],1)],1):(!_vm.loading && !_vm.keyPhrases)?_c('div',[_vm._v(_vm._s(_vm.$t("no-data")))]):(_vm.loading)?_c('Loading'):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }