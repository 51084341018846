<template>
  <button class="speaking-chat__show-hint" @click="toggleHintShown" :disabled="disabled">
    <transition mode="out-in">
      <Icon name="hint-eye-closed" icon-color-name="secondary-color" v-if="!showHint" />
      <Icon name="hint-eye-opened" icon-color-name="secondary-color" v-else />
    </transition>
  </button>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "SpeakingHintToggle",
  props: {
    type: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  mounted() {
    this.clearState();
  },
  methods: {
    toggleHintShown() {
      if (this.type === "question") {
        this.setShowQuestionHint({ hint: !this.showQuestionHint });
      } else {
        this.setShowAnswerHint({ hint: !this.showAnswerHint });
      }
    },
    clearState() {
      this.setShowQuestionHint({ hint: this.isCompleted });
      this.setShowAnswerHint({ hint: this.isCompleted });
    },
    ...mapMutations("speakingStore", ["setShowQuestionHint", "setShowAnswerHint"])
  },
  computed: {
    showHint() {
      if (this.type === "question") {
        return this.questionHintVisible;
      } else {
        return this.answerHintVisible;
      }
    },
    ...mapGetters("speakingStore", ["questionHintVisible", "answerHintVisible", "isCompleted"]),
    ...mapState("speakingStore", ["showQuestionHint", "showAnswerHint", "questionIndex"])
  },
  beforeDestroy() {
    this.setShowQuestionHint({ hint: false });
    this.setShowAnswerHint({ hint: false });
  },
  watch: {
    questionIndex: {
      handler() {
        this.clearState();
      }
    }
  }
};
</script>
