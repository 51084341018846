<template>
  <div
    class="lesson-module"
    ref="keyPhrases"
    v-if="showComponent"
    @click="isIntroJs && introJs.vocabulary ? $intro().exit() : ''"
  >
    <Portal to="lesson-description">
      {{ $t("vocabulary.desc") }}
    </Portal>
    <div class="row columns-14 vocabulary">
      <Portal to="lesson-nav-prev">
        <div class="lesson__nav lesson__nav--prev" ref="previousQuestion">
          <button class="lesson__button" @click="getPreviousQuestion()">
            <Icon size="lesson-nav" class="vocabulary__icon" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>

      <div class="vocabulary__collocation" ref="collocation">
        <VocabularyCard :key="questionIndex" @playerLoaded="showIntroJs" />
      </div>
      <Portal to="lesson-nav-next">
        <div class="lesson__nav lesson__nav--next">
          <button class="lesson__button" @click="getNextQuestion()">
            <Icon size="lesson-nav" class="vocabulary__icon" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>
      <div class="vocabulary__progress-bar" ref="progressBar">
        <ProgressBar :currentProgress="questionIndex" :totalProgressAmount="vocabularyQuestions.length" />
      </div>
    </div>
    <Portal to="module-progress">
      <ProgressBar :currentProgress="questionIndex" :totalProgressAmount="vocabularyQuestions.length" />
    </Portal>
  </div>
  <loading v-else />
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import introJsMixin from "@/mixins/IntroJsMixin";

import ProgressBar from "../ProgressBar";
import VocabularyCard from "../vocabulary/VocabularyCard";
import LanguageChangeMixin from "../../mixins/LanguageChangeMixin";

export default {
  name: "Vocabulary",
  components: { VocabularyCard, ProgressBar },
  computed: {
    setCurrentIntroJsNavButtonElement() {
      return window.innerWidth < 799
        ? ".mobile-nav-wrapper .lesson__nav--next"
        : ".lesson-desktop-nav .lesson__nav--next";
    },
    getNavIconName() {
      return this.navIconName();
    },
    ...mapState("vocabularyStore", ["vocabularyQuestions", "questionIndex", "timerId"]),
    ...mapState("lessonStore", ["cancelRequest"]),
    ...mapState("userStore", ["introJs", "userLanguage"]),
    ...mapGetters("vocabularyStore", ["currentQuestion"]),
    ...mapGetters("userStore", ["isIntroJs"])
  },
  data() {
    return {
      showComponent: false,
      introJsInstance: null
    };
  },
  inject: ["navIconName"],
  mixins: [introJsMixin, LanguageChangeMixin],
  async mounted() {
    await this.getQuestions();
    this.showComponent = true;
    this.$emit("lessonModuleLoaded");

    this.$nextTick(() => {
      if (this.currentQuestion.type !== "quiz_question") {
        this.defineHelpTours();
        this.showIntroJs();
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      if (this.introJsInstance) {
        this.introJsInstance.refresh();
      }
    });
  },
  methods: {
    defineHelpTours() {
      const tours = [
        {
          key: "vocabulary",
          data: {
            steps: [
              {
                title: "1/1",
                element: document.querySelector(".vocabulary__collocation"),
                intro: this.$t("vocabulary-choose-answer.intro")
              }
            ]
          }
        }
      ];

      this.setTours(tours);
    },
    showIntroJs() {
      this.$nextTick(() => {
        this.defineHelpTours();
        this.showIntroFirstTime("vocabulary");
      });
    },
    ...mapMutations("userStore", ["setIntroJs", "setTours"]),
    ...mapActions("vocabularyStore", ["getQuestions", "getNextQuestion", "getPreviousQuestion"])
  },

  watch: {
    questionIndex() {
      this.$nextTick(() => {
        this.defineHelpTours();
      });
    }
  },

  beforeDestroy() {
    this.cancelRequest?.cancel();
    clearInterval(this.timerId);
    window.removeEventListener("resize", () => {
      this.setCurrentIntroJsNavButtonElement();
      if (this.introJsInstance) {
        this.introJsInstance.refresh();
      }
    });
  }
};
</script>
