const LanguageChangeMixin = {
  watch: {
    userLanguage: {
      handler(newVal) {
        if (newVal) {
          this.defineHelpTours();
        }
      },
      immediate: true
    }
  }
};

export default LanguageChangeMixin;
