<template>
  <div class="speaking-chat__approvement-outer-wrapper">
    <div class="speaking-chat__approvement-wrapper speaking-chat__approvement-wrapper--desktop">
      <SpeakingButton
        @click="recordingIsIncorrect"
        class="button-speaking button-speaking--reject"
        :text="$t('speaking-record-incorrect')"
        iconName="record-incorrect"
        iconPosition="right"
      />
      <SpeakingButton
        @click="recordingIsCorrect"
        class="button-speaking button-speaking--approve"
        :text="$t('speaking-record-correct')"
        iconName="record-correct"
        iconPosition="right"
      />
      <Portal to="mobile-nav-slot">
        <div
          class="speaking-chat__approvement-wrapper speaking-chat__approvement-wrapper--mobile"
          v-show="audio && !currentQuestion.completed && isAnswerRecorded"
        >
          <SpeakingButton
            @click="recordingIsIncorrect"
            class="button-speaking button-speaking--reject"
            :text="$t('speaking-record-incorrect')"
            iconName="record-incorrect"
            iconPosition="right"
          />
          <SpeakingButton
            @click="recordingIsCorrect"
            class="button-speaking button-speaking--approve"
            :text="$t('speaking-record-correct')"
            iconName="record-correct"
            iconPosition="right"
          />
        </div>
      </Portal>
    </div>
  </div>
</template>

<script>
import store from "@/store/stores";
import SpeakingButton from "./SpeakingButton.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SpeakingRecordConfirmation",
  components: { SpeakingButton },
  computed: {
    ...mapGetters("speakingStore", ["currentQuestion"]),
    ...mapState("vocabularyStore", ["correctAnswerAudio", "wrongAnswerAudio"]),
    ...mapState("speakingStore", ["audio", "isAnswerRecorded"])
  },
  methods: {
    async recordingIsCorrect() {
      await this.correctAnswerAudio.play();
      this.setRecordingAsCorrect();
      setTimeout(() => {
        this.getNextQuestion();
      }, 600);
    },
    async recordingIsIncorrect() {
      await this.wrongAnswerAudio.play();
      store.commit("speakingStore/setIsAnswerRecorded", { recorded: false });
      store.commit("speakingStore/setAudio", { audio: false });
    },
    ...mapActions("speakingStore", ["setRecordingAsCorrect", "getNextQuestion"])
  }
};
</script>
