import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import HeaderDashboard from "../components/global/HeaderDashboard";
import Icon from "../components/Icon";
import Sidebar from "../components/global/Sidebar";
import MobileMenu from "../components/global/MobileMenu";
import LessonCard from "../components/LessonCard";
import DashboardRight from "../components/DashboardRight";
import InputButton from "../components/form/InputButton";
import Header from "../components/global/Header";
import Timeline from "../components/Timeline";
import Loading from "../components/Loading";
import Podcast from "../components/lesson/Podcast";
import KeyPhrases from "../components/lesson/KeyPhrases";
import Speaking from "../components/lesson/Speaking";
import Vocabulary from "../components/lesson/Vocabulary";
import Summary from "../components/lesson/Summary";
import Player from "../components/Player";
import SplitComponent from "../components/SplitToTranslate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookMessenger, faWhatsapp, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import HeaderMobile from "../components/global/HeaderMobile";

library.add(faFacebookMessenger);
library.add(faWhatsapp);
library.add(faLinkedin);

function GlobalComponents() {
  Vue.component("ValidationProvider", ValidationProvider);
  Vue.component("ValidationObserver", ValidationObserver);
  Vue.component("HeaderDashboard", HeaderDashboard);
  Vue.component("Header", Header);
  Vue.component("HeaderMobile", HeaderMobile);
  Vue.component("Icon", Icon);
  Vue.component("Sidebar", Sidebar);
  Vue.component("MobileMenu", MobileMenu);
  Vue.component("LessonCard", LessonCard);
  Vue.component("DashboardRight", DashboardRight);
  Vue.component("InputButton", InputButton);
  Vue.component("Timeline", Timeline);
  Vue.component("Loading", Loading);
  Vue.component("Player", Player);
  Vue.component("SplitComponent", SplitComponent);
  Vue.component("Podcast", Podcast);
  Vue.component("KeyPhrases", KeyPhrases);
  Vue.component("Speaking", Speaking);
  Vue.component("Vocabulary", Vocabulary);
  Vue.component("Summary", Summary);
  Vue.component("font-awesome-icon", FontAwesomeIcon);
}

export default GlobalComponents;
