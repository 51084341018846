<template>
  <div @click="$emit('click')" class="lesson-card" :id="setRef" :ref="setRef">
    <div class="lesson-card__image">
      <img :src="imageSrc" :alt="title" />
      <div class="lesson-card__badges">
        <div class="lesson-card__level">{{ $t(level) }}</div>
        <div v-if="isCompleted" class="lesson-card__badge">{{ $t("completed") }}</div>
      </div>
      <div v-if="isShowProgress" class="lesson-card__progress">
        <vue-circle
          class="lesson-card__circle"
          :progress="attempt.progress"
          :size="67"
          :reverse="false"
          line-cap="round"
          :fill="{ color: '#E18741' }"
          empty-fill="rgba(0, 0, 0, 0)"
          :animation="false"
          :start-angle="-Math.PI / 2"
          insert-mode="append"
          :thickness="6"
          :show-percent="true"
        >
        </vue-circle>
      </div>
    </div>
    <div class="lesson-card__content">
      <div class="lesson-card__data">
        <div class="lesson-card__title">{{ title }}</div>
        <div class="lesson-card__course">{{ course }}</div>
      </div>

      <div class="lesson-card__info">
        <div class="lesson-card__info-item" v-if="keyPhrasesActive">
          <Icon
            name="list"
            icon-color-name="dark-secondary-color"
            background-color-name="primary-color"
            text="Key Phrases"
            size="small"
          />
          <div class="lesson-card__info-value">{{ keyPhrasesCount }}</div>
        </div>
        <div class="lesson-card__info-item">
          <Icon
            name="help"
            icon-color-name="dark-secondary-color"
            background-color-name="primary-color"
            :text="$t('lesson-card.questions')"
            size="small"
          />
          <div class="lesson-card__info-value">{{ questionsCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCircle from "vue2-circle-progress";

export default {
  name: "LessonCard",
  props: {
    title: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    course: {
      type: String,
      required: true
    },
    keyPhrasesCount: {
      type: Number,
      required: true
    },
    questionsCount: {
      type: Number,
      required: true
    },
    attempt: [Object, Array],
    currentLesson: {
      type: String
    },
    lastCompletedLesson: {
      type: String
    },
    level: {
      type: String
    },
    keyPhrasesActive: {
      type: Boolean
    }
  },

  computed: {
    isCompleted() {
      return this.attempt?.status === "finished";
    },

    isShowProgress() {
      if (!this.attempt) {
        return false;
      }

      return !this.isCompleted && this.attempt.progress > 0;
    },

    setRef() {
      const lesson = this.currentLesson || this.lastCompletedLesson;

      return lesson === this.attempt.uuid ? "current" : null;
    }
  },

  components: {
    VueCircle
  },

  mounted() {
    if (this.$refs.current) {
      this.$refs.current.scrollIntoView({ block: "center" });
    }
  }
};
</script>
