<template>
  <div
    class="timeline"
    :class="{
      'is-loading': !(availableModules.length > 0)
    }"
  >
    <fragment v-if="availableModules.length > 0">
      <div
        v-for="slug in availableModules"
        :key="slug"
        @click="changeLessonModule({ module: slug })"
        class="timeline__item"
      >
        <Icon size="medium" :name="getModuleInfoBySlug(slug).icon" v-bind="isCurrent(slug)" header />
        <div class="timeline__text">{{ $t(getModuleInfoBySlug(slug).text) }}</div>
      </div>

      <div @click="goToSummary" class="timeline__item">
        <Icon size="medium" name="refresh" v-bind="isSummary($route.params.module === 'summary')" header />
        <div class="timeline__text">{{ $t("Summary") }}</div>
      </div>
    </fragment>
    <div v-else>
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapModules, modulesList } from "../store/Lesson/plugins/lessonStorage";

export default {
  name: "Timeline",
  computed: {
    ...mapState("lessonStore", ["availableModules", "currentModule"])
  },

  methods: {
    getModuleInfoBySlug(slug) {
      const moduleIndex = modulesList.indexOf(slug);
      return mapModules[moduleIndex];
    },

    isCurrent(slug) {
      if (slug === this.currentModule) {
        return {
          BackgroundColorName: "secondary-color",
          IconColorName: "white-color",
          type: "outline",
          class: "is-active"
        };
      }

      return {
        BackgroundColorName: "primary-color",
        IconColorName: "dark-secondary-color"
      };
    },

    isSummary(summary) {
      if (summary) {
        return {
          BackgroundColorName: "secondary-color",
          IconColorName: "white-color",
          type: "outline",
          class: "is-active"
        };
      }

      return {
        BackgroundColorName: "primary-color",
        IconColorName: "dark-secondary-color"
      };
    },

    ...mapActions("lessonStore", ["moduleChanged", "finishLesson", "changeLessonModule", "goToSummary"])
  }
};
</script>
