const FormMixin = {
  data: function() {
    return {
      form: {}
    };
  },
  methods: {
    setValue({ name, value }) {
      this.form[name] = value;
    }
  }
};

export default FormMixin;
