<template>
  <div class="translation-item__container" :class="{ 'translation-item__word': phrase.match(/[a-zA-Z]/g) }">
    <div v-if="showPopup && phrase.match(/[a-zA-Z]/g)" class="translation-item__popup">
      <span v-if="translatedWord">{{ translatedWord }}</span>
      <span v-else class="translation-item__loading">
        <loading class="translation-item__loader translation-item__loader--small" />
      </span>
    </div>
    <span class="full-phrase" @click="setWord" v-html="phrase" v-click-outside-element="hidePopup"></span>
  </div>
</template>

<script>
import client from "../helpers/client";
import axios from "axios";
import { mapState, mapMutations } from "vuex";

export default {
  name: "TranslationItem",
  data() {
    return {
      translatedWord: null,
      showPopup: false
    };
  },
  props: {
    phrase: String
  },
  computed: {
    ...mapState("lessonStore", ["cancelRequest"])
  },
  methods: {
    async setWord(e) {
      this.showPopup = true;
      if (this.phrase.match(/[a-zA-Z]/g)) {
        await this.getTranslation(e.target.closest(".full-phrase").textContent);
      }
    },
    hidePopup() {
      if (this.showPopup) {
        this.showPopup = false;
      }
    },
    async getTranslation(text) {
      this.translatedWord = null;
      this.cancelRequest?.cancel();
      this.setCancelRequest(axios.CancelToken.source());
      const {
        data: {
          data: { translatedText }
        }
      } = await client.post(
        `/api/translate-phrase`,
        {
          text
        },
        {
          cancelToken: this.cancelRequest?.token
        }
      );
      this.translatedWord = translatedText;
    },
    ...mapMutations("lessonStore", ["setCancelRequest"])
  }
};
</script>
