<template>
  <div class="progress-bar" ref="progressBar" :class="{ 'progress-bar--full-width': !paginationDisplayed }">
    <div class="progress-bar__linear-outer">
      <div class="progress-bar__linear-inner" :style="{ width: currentPercentageProgress + '%' }"></div>
    </div>
    <span v-if="paginationDisplayed" class="progress-bar__questionNumber"
      >{{ currentProgress + 1 }} / {{ totalProgressAmount }}</span
    >
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    currentProgress: {
      type: Number,
      required: true
    },
    totalProgressAmount: {
      type: Number,
      required: true
    },
    paginationDisplayed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPercentageProgress() {
      return ((this.currentProgress + 1) / this.totalProgressAmount) * 100;
    }
  }
};
</script>
