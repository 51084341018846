<template>
  <div
    v-if="!loading && keyPhrases"
    class="lesson-module"
    @click="isIntroJs && introJs.keyPhrases ? $intro().exit() : ''"
  >
    <Portal to="lesson-description">
      {{ $t("key-phrases.desc") }}
    </Portal>
    <div class="row columns-14 key-phrases">
      <div class="key-phrases__players">
        <div class="key-phrases__player">
          <Player
            ref="phrase"
            @startPlay="stopPlay('usage')"
            :disableTimelineClick="true"
            :stop-button="false"
            :audio-files="getAudio('phrase', selectedKeyPhrase)"
            :showTimer="false"
            @endPlaylist="endPlaylist('phrase')"
            @playerLoaded="onPlayerLoaded"
          />
          <div class="key-phrases__desc">
            <translate-full-phrase :text="getAudioText('phrase', selectedKeyPhrase)"></translate-full-phrase>
          </div>
        </div>
        <div class="key-phrases__player">
          <Player
            ref="usage"
            @startPlay="stopPlay('phrase')"
            :disableTimelineClick="true"
            :stop-button="false"
            :audio-files="getAudio('usage', selectedKeyPhrase)"
            :showTimer="false"
            @endPlaylist="endPlaylist('usage')"
            @playerLoaded="onPlayerLoaded"
          />
          <div class="key-phrases__desc" ref="examplePhraseUsage">
            <translate-full-phrase :text="getAudioText('usage', selectedKeyPhrase)"></translate-full-phrase>
          </div>
        </div>
      </div>
      <div class="key-phrases__vue-scroll" ref="phrasesList">
        <vue-scroll
          :ops="{
            scrollPanel: {
              scrollingX: false,
              scrollingY: true
            },
            vuescroll: {
              detectResize: true,
              sizeStrategy: 'percent'
            },

            rail: { gutterOfSide: '0' }
          }"
        >
          <div class="key-phrases-list">
            <div
              v-for="(keyPhrase, index) in keyPhrases"
              :key="keyPhrase.id"
              class="key-phrases-list__item round-button"
              :class="{
                'is-current': index === selectedKeyPhrase
              }"
              @click="setKeyPhrase(index, keyPhrase.id)"
            >
              {{ keyPhrase.name }}
            </div>
          </div>
        </vue-scroll>
      </div>
      <Portal to="lesson-nav-prev">
        <div class="lesson__nav lesson__nav--prev">
          <button class="lesson__button" @click="handlePrevBtnClick">
            <Icon size="lesson-nav" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>
      <Portal to="lesson-nav-next">
        <div class="lesson__nav lesson__nav--next">
          <button class="lesson__button" @click="handleNextBtnClick">
            <Icon size="lesson-nav" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>
      <Portal to="mobile-nav-slot">
        <div class="lesson__nav lesson__nav--skip">
          <button class="lesson__button" @click="skipAll">
            Skip all
          </button>
        </div>
      </Portal>
    </div>
    <Portal to="module-progress">
      <ProgressBar :currentProgress="selectedKeyPhrase" :totalProgressAmount="keyPhrases.length" />
    </Portal>
  </div>
  <div v-else-if="!loading && !keyPhrases">{{ $t("no-data") }}</div>
  <Loading v-else-if="loading"></Loading>
</template>

<script>
import client from "../../helpers/client";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import introJsMixin from "@/mixins/IntroJsMixin";
import ProgressBar from "../ProgressBar";
import TranslateFullPhrase from "../TranslateFullPhrase";
import LanguageChangeMixin from "../../mixins/LanguageChangeMixin";

export default {
  name: "KeyPhrases",
  components: { TranslateFullPhrase, ProgressBar },
  data() {
    return {
      loading: true,
      keyPhrases: null,
      selectedKeyPhrase: 0
    };
  },
  inject: ["navIconName"],
  mixins: [introJsMixin, LanguageChangeMixin],
  async mounted() {
    await this.getKeyPhrases();

    if (!this.keyPhrases) {
      this.loading = false;
      return;
    }

    await this.chooseKeyPhrase(this.keyPhrases[0].id);
    this.loading = false;
    this.$emit("lessonModuleLoaded");
  },
  computed: {
    getNavIconName() {
      return this.navIconName();
    },
    ...mapState("lessonStore", ["cancelRequest"]),
    ...mapState("userStore", ["introJs", "showHelp", "userLanguage"]),
    ...mapGetters("userStore", ["isIntroJs"])
  },

  methods: {
    formatKeyPhrases(keyPhrases) {
      return keyPhrases.map(phrase => {
        return {
          id: phrase.id,
          name: phrase.name,
          phrase: phrase.phrase,
          usage: {
            audio: phrase.usage.audio,
            text: phrase.usage.text.replace(phrase.name, `<b>${phrase.name}</b>`)
          }
        };
      });
    },
    stopPlay(type) {
      this.$refs[type].stopAudio();
    },

    setKeyPhrase(index, id) {
      if (this.selectedKeyPhrase === index) {
        return;
      }

      this.selectedKeyPhrase = index;
      this.chooseKeyPhrase(id);
    },

    getAudio(type, index) {
      return [this.keyPhrases[index][type].audio];
    },

    getAudioText(type, index) {
      return this.keyPhrases[index][type].text;
    },

    async endPlaylist(type) {
      const { lessonId, attemptId } = this.$route.params;

      await client.post(`/api/lessons/${lessonId}/attempt/${attemptId}/key_phrases/listen/${this.keyPhrases[0].id}`, {
        type
      });
    },

    async chooseKeyPhrase(id) {
      const { lessonId, attemptId } = this.$route.params;
      this.cancelRequest?.cancel();

      this.setCancelRequest(axios.CancelToken.source());
      await client.post(`/api/lessons/${lessonId}/attempt/${attemptId}/key_phrases/choose/${id}`, null, {
        cancelToken: this.cancelRequest?.token
      });
    },

    async getKeyPhrases() {
      const { lessonId, attemptId } = this.$route.params;
      this.cancelRequest?.cancel();

      this.setCancelRequest(axios.CancelToken.source());
      await client
        .get(`/api/lessons/${lessonId}/attempt/${attemptId}/key_phrases`, {
          cancelToken: this.cancelRequest?.token
        })
        .then(response => {
          const phrases = response.data.data.reverse();
          this.keyPhrases = phrases ? this.formatKeyPhrases(phrases) : null;
        });
    },

    handlePrevBtnClick() {
      if (window.innerWidth < 800 && this.selectedKeyPhrase > 0) {
        this.selectedKeyPhrase--;
      } else {
        this.goToPreviousModule(null, { root: true });
      }
    },

    handleNextBtnClick() {
      if (window.innerWidth < 800 && this.selectedKeyPhrase < this.keyPhrases.length - 1) {
        this.selectedKeyPhrase++;
      } else {
        this.goToNextModule(null, { root: true });
      }
    },
    skipAll() {
      this.goToNextModule(null, { root: true });
    },
    defineHelpTours() {
      const tours = [
        {
          key: "keyPhrases",
          data: {
            steps: [
              {
                title: "1/2",
                element: document.querySelector(".key-phrases-list__item:first-of-type"),
                intro: this.$t("key-phrases-choose-phrase.intro")
              },
              {
                title: "2/2",
                element: document.querySelector(".key-phrases__players"),
                intro: this.$t("key-phrases-players.intro")
              }
            ]
          }
        }
      ];

      this.setTours(tours);
    },

    onPlayerLoaded() {
      if (!this.$refs.usage.loaded || !this.$refs.phrase.loaded) return;
      this.$nextTick(() => {
        this.defineHelpTours();
        this.showIntroFirstTime("keyPhrases");
      });
    },

    ...mapMutations("lessonStore", ["setCancelRequest"]),
    ...mapMutations("userStore", ["setIntroJs", "setTours"]),
    ...mapActions("lessonStore", ["goToNextModule", "goToPreviousModule"])
  },

  beforeDestroy() {
    this.cancelRequest?.cancel();
  }
};
</script>
