import client from "../../helpers/client";
import * as speakingPlugins from "./plugins/speakingStorage";
import * as storePlugins from "../plugins/storeStorage";
import axios from "axios";

const speakingStore = {
  namespaced: true,

  state() {
    return {
      isMicrophone: true,
      speakingQuestions: [],
      questionIndex: null,
      recordingIsListened: false,
      isAnswerRecorded: false,
      isAnswerRecording: false,
      timer: 0,
      audioChunks: [],
      audioRecorder: null,
      audio: null,
      stream: null,
      showQuestionHint: false,
      showAnswerHint: false,
      playRecord: false,
      currentAudio: false
    };
  },
  getters: {
    currentQuestion(state) {
      return state.speakingQuestions[state.questionIndex];
    },
    questionAudio(state, getters) {
      return getters.currentQuestion?.audio || null;
    },
    completedQuestionsLength(state) {
      return speakingPlugins.getAmountOfCompletedQuestions(state.speakingQuestions);
    },
    correctAnswerAudio(state, getters) {
      return new Audio(getters.currentQuestion?.audioCorrectAnswer.audio);
    },
    questionHintVisible(state) {
      return state.showQuestionHint;
    },
    answerHintVisible(state) {
      return state.showAnswerHint;
    },
    isCompleted(state) {
      return state.speakingQuestions[state.questionIndex].completed;
    }
  },
  mutations: {
    setSpeakingQuestions(state, { speakingQuestions }) {
      state.speakingQuestions = speakingQuestions;
    },
    setQuestionIndex(state, { questionIndex }) {
      state.questionIndex = questionIndex;
    },
    setRecordingIsListened(state, { listened }) {
      state.recordingIsListened = listened;
    },
    setIsAnswerRecorded(state, { recorded }) {
      state.isAnswerRecorded = recorded;
    },
    setIsAnswerRecording(state, { recording }) {
      state.isAnswerRecording = recording;
    },
    setCurrentQuestionCompleted(state) {
      state.speakingQuestions[state.questionIndex].completed = true;
    },
    setTimer(state, { timer }) {
      state.timer = timer;
    },
    setRecordedAudio(state, { audio }) {
      state.recordedAudio = audio;
    },
    setStream(state, { stream }) {
      state.stream = stream;
    },
    setAudioRecorder(state, { audioRecorder }) {
      state.audioRecorder = audioRecorder;
    },
    setAudio(state, { audio }) {
      state.audio = audio;
    },
    setAudioChunks(state, { audioChunk }) {
      state.audioChunks = [audioChunk];
    },
    setPlayRecord(state, { play }) {
      state.playRecord = play;
    },
    setShowQuestionHint(state, { hint }) {
      state.showQuestionHint = hint;
    },
    setShowAnswerHint(state, { hint }) {
      state.showAnswerHint = hint;
    },
    setCurrentAudio(state, { audio }) {
      state.currentAudio = audio;
    },
    setIsMicrophone(state, { isMicrophone }) {
      state.isMicrophone = isMicrophone;
    }
  },
  actions: {
    setListenerOnCorrectAnswerAudio({ state, getters, dispatch, commit }) {
      getters.correctAnswerAudio.addEventListener("ended", () => {
        commit("setCurrentAudio", { audio: state.audio });
        dispatch("playAudio");
      });
    },
    setRecordingAndRecorded({ commit }, { recording, recorded }) {
      commit("setIsAnswerRecording", { recording });
      commit("setIsAnswerRecorded", { recorded });
    },

    getPreviousQuestion({ state, commit, dispatch }) {
      if (state.questionIndex > 0) {
        dispatch("stopPlayingAudio");
        commit("setQuestionIndex", { questionIndex: state.questionIndex - 1 });
        commit("setIsAnswerRecorded", { recorded: false });
        commit("setShowQuestionHint", { hint: false });
        commit("setShowAnswerHint", { hint: false });
      } else {
        dispatch("stopPlayingAudio");
        dispatch("endMediaStream");
        dispatch("goToPreviousModule", null, { root: true });
      }
      clearTimeout(state.timer);
    },
    getNextQuestion({ state, commit, dispatch }) {
      if (state.questionIndex < state.speakingQuestions.length - 1) {
        commit("setAudio", { audio: null });
        commit("setQuestionIndex", { questionIndex: state.questionIndex + 1 });
        commit("setRecordingIsListened", { listened: false });
        commit("setShowQuestionHint", { hint: false });
        commit("setShowAnswerHint", { hint: false });
        dispatch("stopPlayingAudio");
        dispatch("setRecordingAndRecorded", {
          recording: false,
          recorded: false
        });
      } else {
        dispatch("stopPlayingAudio");
        dispatch("endMediaStream");
        dispatch("goToNextModule", null, { root: true });
      }
      clearTimeout(state.timer);
    },
    async recordAudio({ state, commit, dispatch }, { toast, message }) {
      try {
        await dispatch("startMediaStream");
        dispatch("setRecordingAndRecorded", { recording: true, recorded: false });
        state.audioRecorder.start();
        const timer = setTimeout(() => {
          state.audioRecorder.stop();
          dispatch("setRecordingAndRecorded", {
            recording: false,
            recorded: true
          });
        }, 60000);
        commit("setTimer", { timer: timer });
      } catch (err) {
        commit("setIsMicrophone", { isMicrophone: false });
        toast.error(message);
      }
    },
    async stopRecordAudio({ state, getters, dispatch, commit }) {
      state.audioRecorder.stop();
      clearTimeout(state.timer);
      commit("setCurrentAudio", { audio: getters.correctAnswerAudio });
      await dispatch("endMediaStream");
      dispatch("setRecordingAndRecorded", { recording: false, recorded: true });
      dispatch("playAudio");
    },
    playAudio({ state, commit }) {
      commit("setPlayRecord", { play: true });
      state.currentAudio?.play();
    },
    stopPlayingAudio({ state, commit }) {
      if (state.currentAudio?.duration > 0 && !state.currentAudio?.paused) {
        commit("setPlayRecord", { play: false });
        state.currentAudio.pause();
      }
    },
    async startMediaStream({ state, commit, getters, dispatch }) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      commit("setStream", { stream });
      const audioRecorder = new MediaRecorder(state.stream);
      commit("setAudioRecorder", { audioRecorder });

      state.audioRecorder.addEventListener("dataavailable", event => {
        commit("setAudioChunks", { audioChunk: event.data });
      });

      state.audioRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(state.audioChunks, { type: "audio/mpeg" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        dispatch("setListenerOnCorrectAnswerAudio", { audio });
        audio.addEventListener("ended", () => {
          dispatch("stopPlayingAudio");
          commit("setPlayRecord", { play: false });
          commit("setCurrentAudio", { audio: getters.correctAnswerAudio });
        });
        commit("setAudio", { audio });
      });
    },
    async endMediaStream({ state }) {
      if (state.stream) {
        state.stream.getTracks()[0].stop();
      }
    },
    async getSpeakingQuestions({ state, getters, commit, rootState }) {
      const { lessonId, attemptId } = storePlugins.getRouteParams();

      rootState.lessonStore.cancelRequest?.cancel();
      commit("setIsAnswerRecorded", { recorded: false });
      commit("setIsAnswerRecording", { recording: false });
      commit("setRecordingIsListened", { listened: false });
      commit("lessonStore/setCancelRequest", axios.CancelToken.source(), {
        root: true
      });

      const {
        data: { data }
      } = await client.get(`/api/lessons/${lessonId}/attempt/${attemptId}/speakingQuestions`, {
        cancelToken: rootState.lessonStore.cancelRequest.token
      });

      commit("setSpeakingQuestions", { speakingQuestions: data });
      commit("setQuestionIndex", {
        questionIndex:
          getters.completedQuestionsLength === state.speakingQuestions.length
            ? getters.completedQuestionsLength - 1
            : getters.completedQuestionsLength
      });
    },
    async postCompletedQuestion({ commit, getters, rootState }) {
      const { lessonId, attemptId } = storePlugins.getRouteParams();
      rootState.lessonStore.cancelRequest?.cancel();
      commit("lessonStore/setCancelRequest", axios.CancelToken.source(), {
        root: true
      });
      await client.post(
        `/api/lessons/${lessonId}/attempt/${attemptId}/speaking-question-completed`,
        {
          id: getters.currentQuestion.id
        },
        {
          cancelToken: rootState.lessonStore.cancelRequest?.token
        }
      );
    },
    setRecordingAsCorrect({ state, commit, dispatch }) {
      if (state.audio) {
        dispatch("postCompletedQuestion");
        commit("setCurrentQuestionCompleted");
      }
    }
  }
};

export default speakingStore;
