<template>
  <div class="vocabularyCard">
    <div v-if="currentQuestion.type === 'quiz_question'" class="vocabularyCard__question">
      <div class="vocabularyCard__player-wrapper vocabularyCard__player-wrapper--desktop">
        <Player
          :disableTimelineClick="true"
          :disable-time-line="true"
          :stopButton="false"
          :show-timer="false"
          :audio-files="currentQuestion.audio"
          @playerLoaded="vocabularyPlayerLoaded"
        />
      </div>
      <Portal to="mobile-nav-slot">
        <div class="vocabularyCard__player-wrapper vocabularyCard__player-wrapper--mobile">
          <Player
            :disableTimelineClick="true"
            :disable-time-line="true"
            :stopButton="false"
            :show-timer="false"
            :audio-files="currentQuestion.audio"
            @playerLoaded="vocabularyPlayerLoaded"
          />
        </div>
      </Portal>
      <div v-html="parsedQuestion" />
    </div>

    <div v-else-if="synonymOrAntonym" class="vocabularyCard__question">
      <span class="vocabularyCard__firstComponent" v-html="firstPart" />
      <span class="vocabularyCard__equivalent">{{ mark }}</span>
      <span class="vocabularyCard__secondComponent" v-html="secondPart" />
    </div>

    <div
      v-else-if="currentQuestion.type === 'collocation'"
      class="vocabularyCard__question vocabularyCard__question--collocation"
    >
      <span class="vocabularyCard__firstComponent" v-html="firstPart" />
      <span class="vocabularyCard__secondComponent" v-html="secondPart" />
    </div>

    <div class="vocabularyCard__answers">
      <AnswerButton
        v-for="(answer, index) in answers"
        :text="answer.text"
        :selected="answer.selected"
        :disabled="answer.disabled"
        :correct="answer.correct"
        :index="index"
        :key="`${answer.text}-${index}`"
        @answerClick="answerClick"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AnswerButton from "./AnswerButton";

export default {
  name: "VocabularyCard",
  components: { AnswerButton },

  data() {
    return {
      answers: []
    };
  },

  watch: {
    currentAnswers: {
      handler() {
        this.answers = this.currentAnswers;
      },
      immediate: true
    }
  },

  computed: {
    synonymOrAntonym() {
      return this.currentQuestion.type === "synonym" || this.currentQuestion.type === "antonym";
    },

    mark() {
      return this.currentQuestion.type === "synonym" ? "=" : "≠";
    },

    firstPart() {
      const correctAnswer = this.currentQuestion.firstPart === this.currentQuestion.correctAnswer;

      if (this.currentQuestion.completed && correctAnswer) {
        return `<span ref='gap' class='vocabularyCard__gap'>${this.currentQuestion.firstPart}</span>`;
      } else if (correctAnswer) {
        return `<span ref='gap' class='vocabularyCard__gap'></span>`;
      }

      return this.currentQuestion.firstPart;
    },

    secondPart() {
      const correctAnswer = this.currentQuestion.secondPart === this.currentQuestion.correctAnswer;

      if (this.currentQuestion.completed && correctAnswer) {
        return `<span ref='gap' class='vocabularyCard__gap'>${this.currentQuestion.secondPart}</span>`;
      } else if (correctAnswer) {
        return `<span ref='gap' class='vocabularyCard__gap'></span>`;
      }
      return this.currentQuestion.secondPart;
    },
    ...mapState("vocabularyStore", ["correctAnswerAudio", "wrongAnswerAudio", "timerId"]),
    ...mapGetters("vocabularyStore", ["currentQuestion", "parsedQuestion", "currentAnswers"])
  },

  methods: {
    answerClick({ index, isCorrect }) {
      clearInterval(this.timerId);

      this.wrongAnswerAudio.currentTime = 0;
      const answer = this.answers[index];

      if (answer.selected && !isCorrect) {
        return;
      }

      this.answers.forEach(item => {
        clearTimeout(this.timerId);
        item.selected = false;
        item.correct = false;
      });

      answer.selected = true;
      answer.correct = isCorrect;

      if (!isCorrect) {
        this.wrongAnswerAudio.play();

        const timer = setTimeout(() => {
          answer.selected = false;
          answer.correct = false;
        }, 2000);

        this.setTimerId({ timer });
      } else {
        this.wrongAnswerAudio.pause();
        this.correctAnswerAudio.play();
        this.answers.forEach(item => {
          item.disabled = true;
        });
        const timer = setTimeout(() => {
          this.getNextQuestion();
        }, 2000);

        this.setTimerId({ timer });
      }
    },
    vocabularyPlayerLoaded() {
      this.$emit("playerLoaded");
    },
    ...mapMutations("vocabularyStore", ["setTimerId"]),
    ...mapActions("vocabularyStore", ["getNextQuestion"])
  },
  beforeDestroy() {
    this.correctAnswerAudio.pause();
    this.wrongAnswerAudio.pause();
  }
};
</script>
