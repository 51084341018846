import Tracker from "@openreplay/tracker";
import trackerVuex from "@openreplay/tracker-vuex";

const startTracker = config => {
  const tracker = new Tracker({
    projectKey: config.projectKey,
    __DISABLE_SECURE_MODE: true
  });
  tracker.start();
  return tracker;
};

export function vuexTracker() {
  return startTracker({
    projectKey: process.env.VUE_APP_OPENREPLAY_KEY,
    plugins: {
      vuexTracker: trackerVuex
    }
  });
}
