<template>
  <div class="speaking-chat__item speaking-chat__item--question">
    <player
      :audio-files="[questionAudio.audio]"
      @endPlaylist="setRecordingIsListened({ listened: true })"
      @playerLoaded="onPlayerLoaded"
      disable-time-line
      ref="audio-player"
    />
    <split-component
      :text="questionAudio.text"
      :class="{ 'speaking-chat__item--blurred': !showQuestionHint }"
    ></split-component>
    <SpeakingHintToggle type="question" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SpeakingHintToggle from "./SpeakingHintToggle.vue";

export default {
  name: "SpeakingQuestion",
  components: { SpeakingHintToggle },
  computed: {
    ...mapState("speakingStore", ["showQuestionHint"]),
    ...mapGetters("speakingStore", ["questionAudio"])
  },
  methods: {
    onPlayerLoaded() {
      this.$emit("playerLoaded");
    },
    ...mapMutations("speakingStore", ["setRecordingIsListened"])
  }
};
</script>
