<template>
  <div class="mobile-menu">
    <div class="mobile-menu__header">
      <div class="mobile-menu__logo">
        <router-link :to="{ name: 'Home' }">
          <inline-svg
            :src="require(`../../assets/images/white-logo.svg`)"
            fill="currentColor"
            aria-label="Logo"
          ></inline-svg>
        </router-link>
      </div>
      <div class="mobile-menu__close" @click="$emit('changeMobileMenuState')">
        <inline-svg
          :src="require(`../../assets/icons/cross-big.svg`)"
          fill="currentColor"
          aria-label="Close menu"
        ></inline-svg>
      </div>
    </div>

    <nav class="mobile-menu__menu">
      <ul>
        <li
          v-for="menuItem in menu"
          :key="menuItem.name"
          @click="
            [menuItem.onClick ? [menuItem.onClick(), $emit('changeMobileMenuState')] : $emit('changeMobileMenuState')]
          "
        >
          <router-link v-if="menuItem.condition" :to="menuItem.link ? { name: menuItem.link } : ''">
            <span class="mobile-menu__item">
              <span class="mobile-menu__item-icon">
                <inline-svg
                  :src="require(`../../assets/icons/${menuItem.icon}.svg`)"
                  fill="currentColor"
                  :aria-label="$t(menuItem.title)"
                ></inline-svg>
              </span>
              <span class="mobile-menu__item-title">{{ $t(menuItem.title) }}</span>
            </span>
          </router-link>
        </li>
        <li v-if="isTours">
          <a @click.prevent="showMeHelp" href="#">
            <span class="mobile-menu__item">
              <span class="mobile-menu__item-icon">
                <inline-svg
                  :src="require(`../../assets/icons/help.svg`)"
                  fill="currentColor"
                  :aria-label="$t('help.tour')"
                ></inline-svg>
              </span>
              <span class="mobile-menu__item-title">{{ $t("help.tour") }}</span>
            </span>
          </a>
        </li>
        <li v-if="!demo">
          <a @click.prevent="logOut" href="#">
            <span class="mobile-menu__item">
              <span class="mobile-menu__item-icon">
                <inline-svg
                  :src="require(`../../assets/icons/logout.svg`)"
                  fill="currentColor"
                  :aria-label="$t('log-out')"
                ></inline-svg>
              </span>
              <span class="mobile-menu__item-title">{{ $t("log-out") }}</span>
            </span>
          </a>
        </li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import introJsMixin from "../../mixins/IntroJsMixin";

export default {
  name: "MobileMenu",
  components: { LanguageSwitcher },
  props: {
    menu: Array
  },
  data() {
    return {
      menuItemType: "router-link"
    };
  },
  computed: {
    ...mapState("userStore", ["demo"])
  },
  methods: {
    showMeHelp() {
      if (!this.isTours) {
        return;
      }
      this.$emit("changeMobileMenuState");
      this.startIntro({ module: this.getFirstTourKey });
    },
    ...mapActions("userStore", ["logOutUser"]),
    ...mapMutations("userStore", ["setIntroJs", "setTours"]),

    async logOut() {
      await this.logOutUser({ path: "Login" });
    }
  },
  mixins: [introJsMixin]
};
</script>
