<template>
  <button
    class="podcast__tab-btn-item"
    :disabled="disabled[data]"
    :class="active ? 'podcast__tab-btn-item--active' : ''"
    @click="$emit('tabChanged', data)"
  >
    <Icon size="lesson-tab" class="vocabulary__icon" :class="{ 'icon-w-stroke': data === 'image' }" :name="data" />
    <span>{{ data.toUpperCase() }}</span>
  </button>
</template>

<script>
export default {
  name: "PodcastTab",
  props: {
    data: {
      type: String
    },
    active: {
      type: Boolean
    },
    disabled: {
      type: Object
    }
  }
};
</script>

<style scoped></style>
