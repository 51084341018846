<template>
  <div class="head">
    <div class="top-user">
      <div class="top-user__avatar">
        <img :src="getAvatar" :alt="`${fullName} avatar`" />
      </div>
      <div class="top-user__info">
        <div class="top-user__title">{{ $t("user-title") }} {{ fullName }}</div>
        <div v-if="description" class="top-user__desc">{{ description }}</div>
      </div>
    </div>
    <div class="top-activity" v-if="!demo && !loading">
      <div class="top-activity__item">
        <div class="top-badge">
          <div class="top-badge__icon">
            <Icon
              name="calendar"
              type="outline"
              icon-color-name="white-color"
              background-color-name="secondary-color"
              header
            ></Icon>
          </div>
          <div class="top-badge__text">
            <div class="top-badge__title">{{ $t("last-access") }}</div>
            <div class="top-badge__desc">{{ lastAccessTime }}</div>
          </div>
        </div>
      </div>
      <div class="top-activity__item">
        <div class="top-badge">
          <div class="top-badge__icon">
            <Icon
              name="time-circle"
              type="outline"
              icon-color-name="white-color"
              background-color-name="orange-color"
              header
            ></Icon>
          </div>
          <div class="top-badge__text">
            <div class="top-badge__title">{{ $t("learning-time") }}</div>
            <div class="top-badge__desc">{{ time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Header",
  props: {
    description: String
  },

  computed: {
    getAvatar() {
      return this.avatar ?? "/default-user-avatar-thumb.jpg";
    },
    ...mapState("userStore", ["fullName", "avatar", "demo", "time"]),
    ...mapGetters("userStore", ["lastAccessTime"]),
    ...mapState("pathsStore", ["loading"])
  }
};
</script>
