import client from "../../helpers/client";
import * as userPlugins from "./plugins/userStorage";
import Vue from "vue";
import router from "../../router";
import axios from "axios";

const userStore = {
  namespaced: true,

  state: () => ({
    fullName: "-",
    avatar: null,
    id: null,
    email: null,
    demo: null,
    isIntroShown: null,
    isUserLogin: false,
    userLanguage: navigator.language.split("-")[0],
    lastAccessTimestamp: "",
    time: "-",
    currentPathId: null,
    introJs: {
      popup: true,
      dashboard: true,
      podcast: true,
      keyPhrases: true,
      vocabulary: true,
      speaking: true,
      speakingAnswer: true
    },
    completedLessons: null,
    isLoading: true,
    tokens: null,
    tours: []
  }),
  mutations: {
    setTours(state, tours) {
      state.tours = tours;
    },
    setTokens(state, tokens) {
      if (tokens) {
        window.localStorage.setItem("token", JSON.stringify({ ...tokens, expires_at: Date.now() + tokens.expires_in }));
      }

      state.tokens = tokens;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },

    setUserLogin(state, isLogin) {
      state.isUserLogin = isLogin;
    },

    setUserData(state, userData) {
      const { name, avatar, id, isDemo, stats, isWelcomePopupShown, isIntroShown, currentPathId, email } = userData;
      const { lastAccess, time } = stats;
      state.fullName = name;
      state.avatar = avatar;
      state.id = id;
      state.email = email;
      state.demo = isDemo;
      state.lastAccessTimestamp = lastAccess;
      state.isIntroShown = isIntroShown;
      state.time = time;
      state.userLanguage = navigator.language.split("-")[0];
      state.isWelcomePopupShown = isWelcomePopupShown;
      state.currentPathId = currentPathId;
    },

    clearUserData(state) {
      state.fullName = null;
      state.avatar = null;
      state.id = null;
      state.isUserLogin = false;
      state.lastAccess = "";
      state.time = "";
      state.demo = null;
      state.isWelcomePopupShown = null;
      state.userLanguage = null;
      state.path = null;
      state.currentPathId = null;
      state.lastAccessTimestamp = null;
      state.isIntroShown = null;
      state.email = null;

      window.localStorage.removeItem("vuex");
    },

    setCurrentPathId(state, currentPathId) {
      state.currentPathId = currentPathId;
    },

    setUserLanguage(state, { language }) {
      state.userLanguage = language;
    },

    setUserAvatar(state, { avatar }) {
      state.avatar = avatar;
    },

    setIntroInitialData(state, data) {
      state.introJs = data;
    },

    setIntroJs(state, { key, status }) {
      Vue.set(state.introJs, key, status);
      window.localStorage.setItem("introJs", JSON.stringify(state.introJs));
    }
  },

  actions: {
    async setUserLoginData({ commit }, { data }) {
      commit("setUserLogin", true);
      commit("setUserData", data.data);
    },

    async logInUser({ commit }, { email, password, form, toast }) {
      return await client
        .post("/auth/login", {
          email,
          password
        })
        .catch(error => {
          commit("setUserLogin", false);
          if (error.response) {
            const { message, messages, errors } = error.response.data;

            if (message === "The given data was invalid.") {
              form.setErrors({
                email: [""],
                password: [""]
              });

              toast.error(errors.email[0]);
            } else if (messages?.[0]) {
              toast.error(messages[0]);
            } else {
              form.setErrors(errors);
            }
          }

          return error;
        });
    },
    async sendCurrentPathId({ state, commit }, { currentPathId }) {
      return await client
        .post("/api/set-current-path", {
          path_id: currentPathId,
          user_id: state.id
        })
        .then(() => {
          commit("setCurrentPathId", currentPathId);
          return currentPathId;
        });
    },
    // eslint-disable-next-line no-empty-pattern
    async logOutUser({ state }, { path }) {
      await axios.post(`${process.env.VUE_APP_AUTH_URL}/logout`, null, {
        headers: {
          Authorization: "Bearer " + state.tokens?.access_token
        },
        withCredentials: true
      });
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("demoLesson");
      window.sessionStorage.removeItem("pkce_code_verifier");
      window.sessionStorage.removeItem("pkce_state");
      window.localStorage.setItem("logout", Date.now());

      let logoutCallbackURL = "/login";
      if (path === "Registration") {
        logoutCallbackURL = "/register";
      }
      window.location.replace(process.env.VUE_APP_AUTH_URL + logoutCallbackURL);
    },

    async getUserDetails({ commit }) {
      return await client
        .get("/api/auth-user")
        .then(response => {
          commit("setUserData", response.data.data);
          return response;
        })
        .catch(e => {
          console.error(e);
          window.localStorage.removeItem("token");
          window.location.replace(router.app.$pkce.authorizeUrl());
        });
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isUserLogin;
    },
    lessonIdList(state) {
      return state.path?.lessons.map(lesson => lesson.id) ?? [];
    },
    lastAccessTime(state) {
      return state.lastAccessTimestamp ? userPlugins.timestampToTime(state.lastAccessTimestamp) : "-";
    },
    isIntroJs(state) {
      return state.demo || !state.isIntroShown;
    },
    getPathID(state) {
      return state.currentPathId;
    }
  }
};
export default userStore;
