var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-scroll',{ref:"vs",attrs:{"ops":{
      scrollPanel: {
        scrollingX: false,
        scrollingY: true
      },
      vuescroll: {
        detectResize: true,
        sizeStrategy: 'percent'
      },
      rail: { gutterOfSide: '0' }
    }}},[_c('div',{staticClass:"speaking-chat__wrapper"},[_c('div',{staticClass:"speaking-chat__inner-wrapper"},[_c('SpeakingQuestion',{on:{"playerLoaded":function($event){return _vm.$emit('playerLoaded')}}}),_c('div',{staticClass:"speaking-answer__wrapper"},[_c('SpeakingAnswerRecorder'),_c('SpeakingAnswer')],1),_c('fade-transition',[_c('SpeakingRecordConfirmation',{directives:[{name:"show",rawName:"v-show",value:(_vm.audio && !_vm.currentQuestion.completed && _vm.isAnswerRecorded),expression:"audio && !currentQuestion.completed && isAnswerRecorded"}],ref:"speakingRecordConfirmation"})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }