<template>
  <div class="split-to-translate__container">
    <div class="split-to-translate__items">
      <translation-item class="split-to-translate__item" :phrase="text"></translation-item>
    </div>
  </div>
</template>

<script>
import TranslationItem from "./TranslationItem";
export default {
  name: "TranslateFullPhrase",
  components: { TranslationItem },
  props: {
    text: String
  }
};
</script>
