<template>
  <div class="sidebar-wrapper">
    <div class="sidebar">
      <div class="sidebar__top">
        <div class="sidebar__logo">
          <router-link :to="{ name: 'Home' }">
            <inline-svg :src="require(`../../assets/images/white-logo.svg`)" fill="currentColor" aria-label="Logo" />
          </router-link>
        </div>
      </div>

      <div class="sidebar__middle" v-if="!loading">
        <nav class="sidebar__menu" :class="{ 'sidebar__menu--demo': demo }">
          <ul>
            <li
              v-for="menuItem in menu"
              :key="menuItem.title"
              @click="menuItem.onClick ? menuItem.onClick($event) : ''"
              :class="{ 'sidebar__menu-item--invisible': !menuItem.condition }"
              v-tooltip="menuItem.tooltip"
            >
              <router-link v-if="menuItem.condition" :to="menuItem.link ? { name: menuItem.link } : ''">
                <Icon :name="menuItem.icon" v-bind="isCurrent(menuItem)" :class="['simple-icon--sidebar']" />
              </router-link>
            </li>

            <li
              @click="showMeHelp"
              class="sidebar__menu-item"
              :class="{ 'sidebar__menu--disabled-link': !isTours }"
              ref="helpButton"
              aria-describedby="help-tooltip"
              v-tooltip="!isTours ? $t('help.no-topics') : $t('help.tour')"
            >
              <Icon name="help" icon-color-name="white-color" :class="['simple-icon--sidebar']" />
            </li>
            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </nav>
      </div>

      <div class="sidebar__bottom" v-if="!loading">
        <div v-if="!demo" class="sidebar__logout" @click="logOut($event, 'Login')" v-tooltip="$t('log-out')">
          <Icon name="logout" icon-color-name="white-color" :class="['simple-icon--sidebar']" />
        </div>
      </div>

      <div class="sidebar__burger" @click="changeMobileMenuState">
        <inline-svg :src="require(`../../assets/icons/burger.svg`)" fill="currentColor" aria-label="Mobile Menu" />
      </div>
      <slide-y-up-transition :duration="800">
        <MobileMenu v-show="isMobileMenuOpen" :menu="menu" @changeMobileMenuState="changeMobileMenuState" />
      </slide-y-up-transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import WelcomePopup from "../popups/WelcomePopup";
import SharePopup from "../popups/SharePopup";
import ChangePathPopup from "../popups/ChangePathPopup";
import introJsMixin from "../../mixins/IntroJsMixin";
import LanguageSwitcher from "./LanguageSwitcher.vue";

export default {
  name: "Sidebar",
  components: { LanguageSwitcher },
  data() {
    return {
      isMobileMenuOpen: false
    };
  },
  computed: {
    menu() {
      return [
        {
          name: "Home",
          title: "Home",
          link: "Home",
          icon: "home",
          condition: !this.demo,
          tooltip: this.$t("Home")
        },
        {
          name: "Settings",
          title: "settings",
          link: "Settings",
          icon: "settings",
          condition: !this.demo,
          tooltip: this.$t("settings")
        },
        {
          name: "changePath",
          title: "change-path.change",
          icon: "refresh",
          condition: !this.demo && this.paths?.length > 1,
          onClick: this.changePath,
          tooltip: this.$t("change-path.change")
        },
        {
          name: "languageImprove",
          title: "sidebar.improve-language",
          icon: "sign-up",
          condition: this.demo,
          onClick: this.logOut,
          tooltip: this.$t("sign-up")
        },
        {
          name: "changeLevel",
          title: "sidebar.change-level",
          icon: "refresh",
          condition: this.demo,
          onClick: this.changeLessonLevel,
          tooltip: this.$t("sidebar.change-level")
        },
        {
          name: "shareLesson",
          title: "sidebar.share",
          icon: "share",
          condition: this.demo,
          onClick: this.shareDemoLesson,
          tooltip: this.$t("sidebar.share")
        }
      ];
    },
    ...mapState("userStore", ["demo"]),
    ...mapState("pathsStore", ["paths", "loading"])
  },
  methods: {
    async logOut(event, path) {
      event.preventDefault();
      await this.logOutUser({ path: path ? path : "Registration" });
    },
    isCurrent(menuItem) {
      const name = menuItem.name;
      const currentRouteName = this.$route.name;

      if (name === currentRouteName) {
        return {
          IconColorName: "dark-secondary-color",
          class: "is-active"
        };
      }

      return {
        IconColorName: "white-color"
      };
    },

    changeMobileMenuState() {
      introJs().exit();
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      this.$emit("changeMobileMenuState", this.isMobileMenuOpen);
    },

    changeLessonLevel() {
      if (this.$route.name !== "Home") {
        this.$router.push({ name: "Home" });
        return;
      }
      this.$modal.show(
        WelcomePopup,
        {
          demo: true,
          header: this.$t("welcome.title"),
          description: this.$t("welcome.desc"),
          buttons: {
            beginner: "easy",
            intermediate: "medium",
            advanced: "hard"
          }
        },
        {
          name: "welcomePopup",
          adaptive: true,
          scrollable: true,
          classes: "default-popup",
          height: "auto",
          width: "40%",
          minWidth: 350,
          clickToClose: false
        },
        {
          opened: () => {
            this.$root.$el.classList.add("application__blurred");
          },
          closed: () => {
            this.$root.$el.classList.remove("application__blurred");
          }
        }
      );
      this.$emit("changeLesson");
    },

    changePath() {
      this.$modal.show(
        ChangePathPopup,
        {
          header: this.$t("change-path.header"),
          description: this.$t("change-path.description")
        },
        {
          name: "changPathPopup",
          adaptive: true,
          scrollable: true,
          classes: "default-popup",
          height: "auto",
          width: "40%",
          minWidth: 350,
          clickToClose: true
        },
        {
          opened: () => {
            this.$root.$el.classList.add("application__blurred");
          },
          closed: () => {
            this.$root.$el.classList.remove("application__blurred");
          }
        }
      );
      this.$emit("changeLesson");
    },

    shareDemoLesson() {
      this.$modal.show(
        SharePopup,
        {},
        {
          name: "sharePopup",
          adaptive: true,
          scrollable: true,
          classes: "default-popup",
          height: "auto",
          width: "40%",
          minWidth: 350,
          clickToClose: true
        },
        {
          opened: () => {
            this.$root.$el.classList.add("application__blurred");
          },
          closed: () => {
            this.$root.$el.classList.remove("application__blurred");
          }
        }
      );
    },

    showMeHelp() {
      if (!this.isTours) {
        return;
      }

      this.startIntro({ module: this.getFirstTourKey });
    },

    ...mapMutations("userStore", ["setIntroJs", "setTours"]),
    ...mapActions("userStore", ["logOutUser"])
  },
  mixins: [introJsMixin],
  watch: {
    isMobileMenuOpen: {
      handler(newVal) {
        if (newVal) {
          this.$emit("mobileMenuStateChange", this.isMobileMenuOpen);
        }
      }
    }
  }
};
</script>
