<template>
  <div class="image-placeholder" :class="{ 'is-loaded': isImageLoaded }">
    <div class="speaking-answer__header speaking-answer__header--image" v-if="currentQuestion.imageUrl">
      <img class="speaking-answer__image" :src="currentQuestion.imageUrl" @load="onImgLoaded" alt="" />
      <div class="loader" v-if="!isImageLoaded">
        <loading></loading>
      </div>
    </div>
    <div class="no-image" v-else>No Image</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SpeakingImage",
  data() {
    return {
      isImageLoaded: false
    };
  },
  methods: {
    onImgLoaded() {
      this.isImageLoaded = true;
      this.$emit("answerImgLoaded");
    }
  },
  computed: {
    ...mapGetters("speakingStore", ["currentQuestion"])
  }
};
</script>

<style lang="scss" scoped>
.image-placeholder {
  .speaking-answer__header {
    position: relative;
  }

  .loader {
    height: 100%;
  }

  .no-image {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tab-background-color);
    border-radius: 30px;
  }
}
</style>
