<template>
  <ul class="language-switcher">
    <li
      v-for="locale in availableLocales"
      :key="locale"
      :class="{ 'current-locale': locale === currentTranslation }"
      @click="changeLocale(locale)"
    >
      {{ locale }}
    </li>
  </ul>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      availableLocales: this.$i18n.availableLocales
    };
  },
  computed: {
    currentTranslation() {
      return this.availableLocales.indexOf(this.userLanguage) > -1 ? this.userLanguage : this.$i18n.fallbackLocale;
    },
    ...mapState("userStore", ["userLanguage"])
  },
  methods: {
    changeLocale(locale) {
      if (locale === this.$i18n.locale) return;
      this.$i18n.locale = locale;
      this.setUserLanguage({ language: locale });
    },
    ...mapMutations("userStore", ["setUserLanguage"])
  }
};
</script>
