<template>
  <div class="primary-button">
    <component
      :is="buttonType"
      :type="action"
      class="primary-button__action"
      :class="{
        'primary-button__action--small': small,
        'primary-button__action--outline': outline,
        'is-loading': loading
      }"
      v-bind="props"
      @click="$emit('click')"
    >
      <Loading v-if="loading"></Loading>
      <span v-if="arrowPosition === 'left'" class="primary-button__prev">
        <inline-svg :src="require(`../../assets/icons/prev.svg`)" fill="currentColor"></inline-svg>
      </span>
      {{ $t(text) }}
      <span v-if="arrowPosition === 'right'" class="primary-button__next">
        <inline-svg :src="require(`../../assets/icons/next.svg`)" fill="currentColor"></inline-svg>
      </span>
    </component>
  </div>
</template>

<script>
export default {
  name: "InputButton",
  props: {
    text: String,
    action: String,
    to: Object,
    loading: Boolean,
    arrowPosition: String,
    small: Boolean,
    outline: Boolean
  },

  data() {
    return {
      buttonType: "button",
      props: {}
    };
  },

  mounted() {
    if (this.action === "link") {
      this.buttonType = "RouterLink";
      this.props.to = this.to;
    }
  },

  watch: {
    loading(newVal) {
      const props = this.props;
      props.class = newVal ? "is-loading" : "";
      props.disabled = newVal;

      this.props = { ...props };
    }
  }
};
</script>
