import Vuex from "vuex";
import userStore from "./User/userStore";
import createPersistedState from "vuex-persistedstate";
import Vue from "vue";
import lessonStore from "./Lesson/lessonStore";
import vocabularyStore from "./Vocabulary/vocabularyStore";
import speakingStore from "./Speaking/speakingStore";
import pathsStore from "./Paths/pathsStore";
import { vuexTracker } from "../../tracker/openReplay";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userStore: userStore,
    lessonStore: lessonStore,
    vocabularyStore: vocabularyStore,
    speakingStore: speakingStore,
    pathsStore: pathsStore
  },
  plugins: [
    createPersistedState({
      key: "demoLesson",
      paths: ["lessonStore.demoLessonLevel"]
    }),
    vuexTracker
  ]
});
