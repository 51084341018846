<template>
  <div class="lesson-module" v-if="!loading" @click="isIntroJs && introJs.speaking ? $intro().exit() : ''">
    <Portal to="lesson-description">
      {{ $t("speaking.desc") }}
    </Portal>
    <div class="row columns-14 speaking">
      <Portal to="lesson-nav-prev">
        <div class="lesson__nav lesson__nav--prev">
          <button class="lesson__button" @click="getPreviousQuestion">
            <Icon size="lesson-nav" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>
      <div class="speaking__question row columns-14">
        <SpeakingImage class="speaking__image" @answerImgLoaded="answerImgIsLoaded" :key="currentQuestion.imageUrl" />
        <SpeakingChat
          class="speaking__chat"
          @playerLoaded="onPlayerLoaded"
          @recordConfirmationRendered="showRecordIntro"
          ref="speakingChat"
        />
        <div class="speaking__progress-bar">
          <ProgressBar :currentProgress="getCurrentQuestionIndex" :totalProgressAmount="speakingQuestions.length" />
        </div>
      </div>
      <Portal to="lesson-nav-next">
        <div class="lesson__nav lesson__nav--next">
          <button class="lesson__button" @click="getNextQuestion">
            <Icon size="lesson-nav" :name="getNavIconName" icon-color-name="secondary-color" />
          </button>
        </div>
      </Portal>
    </div>
    <Portal to="module-progress">
      <ProgressBar :currentProgress="getCurrentQuestionIndex" :totalProgressAmount="speakingQuestions.length" />
    </Portal>
  </div>
  <loading v-else />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import introJsMixin from "@/mixins/IntroJsMixin";

import ProgressBar from "../ProgressBar";
import SpeakingImage from "../speaking/SpeakingImage.vue";
import SpeakingChat from "../speaking/SpeakingChat.vue";
import { useMediaQuery } from "@vueuse/core";
import LanguageChangeMixin from "../../mixins/LanguageChangeMixin";

export default {
  name: "Speaking",
  components: { SpeakingChat, SpeakingImage, ProgressBar },
  data() {
    return {
      loading: true,
      playerLoaded: false,
      answerImgLoaded: false,
      chatRecordApprovementElement: document.querySelector(".speaking-chat__approvement-wrapper--desktop")
    };
  },
  inject: ["navIconName"],
  mixins: [introJsMixin, LanguageChangeMixin],
  async mounted() {
    await this.getSpeakingQuestions();
    this.loading = false;
    this.$emit("lessonModuleLoaded");
  },
  beforeDestroy() {
    this.endMediaStream();
  },

  computed: {
    getNavIconName() {
      return this.navIconName();
    },
    getCurrentQuestionIndex() {
      return this.speakingQuestions.indexOf(this.currentQuestion);
    },
    ...mapState("userStore", ["id", "introJs", "userLanguage"]),
    ...mapState("speakingStore", ["speakingQuestions", "isAnswerRecorded"]),
    ...mapGetters("speakingStore", ["currentQuestion", "correctAnswerAudio"]),
    ...mapGetters("userStore", ["isIntroJs"])
  },
  methods: {
    answerImgIsLoaded() {
      this.answerImgLoaded = true;
    },
    defineHelpTours() {
      const tours = [
        {
          key: "speaking",
          data: {
            steps: [
              {
                title: "1/4",
                element: document.querySelector(".speaking-chat__item--question .audio-player__play"),
                intro: this.$t("speaking-click-play.intro")
              },
              {
                title: "2/4",
                element: document.querySelector(".speaking-chat__item--question .speaking-chat__show-hint"),
                intro: this.$t("speaking-question-click-hint.intro")
              },
              {
                title: "3/4",
                element: document.querySelector(".speaking-chat__item--recorder"),
                intro: this.$t("speaking-record.intro")
              },
              {
                title: "4/4",
                element: document.querySelector(".speaking-answer__wrapper .speaking-chat__item:last-of-type"),
                intro: this.$t("speaking-answer.intro")
              }
            ]
          }
        },
        {
          key: "speakingAnswer",
          data: {
            steps: [
              {
                title: "1/1",
                element: this.chatRecordApprovementElement,
                intro: this.$t("speaking-answer.intro")
              }
            ]
          }
        }
      ];

      const conditionalTour = {
        key: "speakingCombined",
        data: {
          steps: [
            {
              title: "1/5",
              element: document.querySelector(".speaking-chat__item--question .audio-player__play"),
              intro: this.$t("speaking-click-play.intro")
            },
            {
              title: "2/5",
              element: document.querySelector(".speaking-chat__item--question .speaking-chat__show-hint"),
              intro: this.$t("speaking-question-click-hint.intro")
            },
            {
              title: "3/5",
              element: document.querySelector(".speaking-chat__item--recorder"),
              intro: this.$t("speaking-record.intro")
            },
            {
              title: "4/5",
              element: document.querySelector(".speaking-answer__wrapper .speaking-chat__item:last-of-type"),
              intro: this.$t("speaking-answer.intro")
            },
            {
              title: "5/5",
              element: this.chatRecordApprovementElement,
              intro: this.$t("speaking-answer-approve.intro")
            }
          ]
        }
      };

      if (this.isAnswerRecorded) {
        tours.unshift(conditionalTour);
      } else {
        tours.push(conditionalTour);
      }
      this.setTours(tours);
    },
    showIntroJs() {
      this.defineHelpTours();
      this.showIntroFirstTime("speaking");
    },
    onPlayerLoaded() {
      this.playerLoaded = true;
    },
    showRecordIntro() {
      this.showIntroFirstTime("speakingAnswer");
    },
    isMobileView() {
      return useMediaQuery("(max-width: 798px)").value;
    },
    ...mapMutations("userStore", ["setIntroJs", "setTours"]),
    ...mapMutations("speakingStore", ["setIsMicrophone"]),
    ...mapActions("speakingStore", [
      "getSpeakingQuestions",
      "getPreviousQuestion",
      "getNextQuestion",
      "endMediaStream",
      "setListenerOnCorrectAnswerAudio"
    ])
  },
  watch: {
    playerLoaded: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.showIntroJs();
          });
        }
      },
      immediate: true
    },
    isAnswerRecorded: {
      handler(newVal, oldVal) {
        if (newVal || oldVal) {
          this.chatRecordApprovementElement = this.isMobileView()
            ? document.querySelector(".speaking-chat__approvement-wrapper--mobile")
            : document.querySelector(".speaking-chat__approvement-wrapper--desktop");
          this.$nextTick(() => {
            this.showIntroJs();
          });
        }
      },
      immediate: true
    }
  }
};
</script>
