<template>
  <component
    :is="buttonType"
    @click="$emit('click')"
    :disabled="disabled"
    :class="{ 'button-speaking--reversed': iconPosition === 'right' }"
  >
    <inline-svg :src="require(`../../assets/icons/${iconName}.svg`)"></inline-svg>
    <span>{{ $t(text) }}</span>
  </component>
</template>

<script>
export default {
  name: "SpeakingButton",
  props: {
    text: String,
    iconName: String,
    iconPosition: String,
    disabled: Boolean
  },
  data() {
    return {
      buttonType: "button"
    };
  }
};
</script>
