<template>
  <div class="popup">
    <div class="h2 popup__title">
      {{ header }}
    </div>
    <div class="popup__desc">
      {{ description }}
    </div>
    <div :class="demo ? 'popup__buttons' : 'popup__buttons--welcome'">
      <div class="popup__item" v-for="(level, name) in buttons" :key="`${level}`">
        <InputButton :text="$t(level)" action="button" @click="demo ? setLessonLevel(name) : $emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "WelcomePopup",
  data() {
    return {
      firstLoginMessage: ["lets-get-started"]
    };
  },
  props: {
    demo: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttons: {
      type: Object,
      required: true
    }
  },
  methods: {
    setLessonLevel(level) {
      this.setDemoLessonLevel({ level: this.buttons[level] });
      this.$emit("close");
    },
    ...mapMutations("lessonStore", ["setDemoLessonLevel"])
  }
};
</script>
