<template>
  <span
    class="simple-icon"
    :class="[
      {
        'simple-icon--big': size === 'big',
        'simple-icon--small': size === 'small',
        'simple-icon--medium': size === 'medium',
        'simple-icon--lesson-nav': size === 'lesson-nav',
        'simple-icon--lesson-tab': size === 'lesson-tab'
      }
    ]"
  >
    <span
      class="simple-icon__wrapper"
      :class="[
        {
          'is-outline': type === 'outline',
          'is-shadow': type === 'shadow'
        }
      ]"
      :style="{
        color: BackgroundColorName ? `var(--${BackgroundColorName})` : 'transparent'
      }"
    >
      <span
        class="simple-icon__inner"
        :style="{
          color: IconColorName ? `var(--${IconColorName})` : 'transparent',
          'background-color': BackgroundColorName ? `var(--${BackgroundColorName})` : 'transparent'
        }"
      >
        <inline-svg
          :src="require(`../assets/icons/${name}.svg`)"
          fill="currentColor"
          :aria-label="name"
          :width="width"
          :height="height"
        ></inline-svg>
      </span>
    </span>

    <span v-if="text" class="simple-icon__text">{{ $t(text) }}</span>
  </span>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",

  props: {
    name: {
      type: String,
      required: true
    },
    IconColorName: {
      type: String
    },
    BackgroundColorName: {
      type: String
    },
    StrokeColorName: {
      type: String
    },
    type: {
      type: String
    },
    text: {
      type: String
    },
    size: {
      type: String
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    }
  },

  components: {
    InlineSvg
  }
};
</script>
