import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import i18n from "./i18n";

const FILE_REGEX = /\.(jpg|svg|jpeg|png|bmp|gif)$/i;

function validateImage(file) {
  return new Promise(resolve => {
    const image = new Image();
    image.onerror = () => resolve({ valid: false });
    image.onload = () =>
      resolve({
        valid: image.width <= 500 && image.height <= 500
      });

    image.src = URL.createObjectURL(file);
  });
}

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: i18n.t(rule) // assign message
  });
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: i18n.t("password-confirmation-does-not-match")
});

extend("maxdimensions", {
  validate(file) {
    return Promise.resolve(FILE_REGEX.test(file.name) ? validateImage(file) : false);
  },
  message: i18n.t("settings-avatar-picture-validation")
});
