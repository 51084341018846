export function formatVocabularyQuestions(questions) {
  return questions.map(question => {
    const gap = question.gap_component;

    return {
      ...(question.audio && { audio: [question.audio] }),
      completed: question.completed,
      uuid: question.uuid,
      type: question.type,
      ...(question[gap] && { correctAnswer: question[gap] }),
      ...(question.first_component && { firstPart: question.first_component }),
      ...(question.second_component && {
        secondPart: question.second_component
      }),

      ...(question.question && { question: question.question }),
      [question.gaps ? "gaps" : "wrongAnswers"]:
        question.type === "quiz_question"
          ? question.gaps.map(gap => {
              return {
                uuid: gap.gap_uuid,
                correctAnswer: gap.correct_answer,
                wrongAnswers: gap.wrong_answers,
                completed: question.completed
              };
            })
          : question.wrong_answers
    };
  });
}

export function getAnswers(question) {
  const { completed, correctAnswer } = question;
  const answers = [...question.wrongAnswers, correctAnswer];

  return formatQuestionAnswers({ answers, completed, correctAnswer });
}

export function parseQuestion(currentQuestion, gapIndex, completedGapsLength) {
  const questionText = currentQuestion.question;
  const currentQuestionGaps = currentQuestion.gaps;

  let currentGapIndex = 0;
  let completedGapIndex = 0;

  return questionText.replace(/{{{gap}}}/g, () => {
    if (completedGapsLength && currentGapIndex < completedGapsLength) {
      const gap = `<span class='vocabularyCard__gap'>
                                ${[currentQuestionGaps?.[completedGapIndex].correctAnswer]}</span>`;
      completedGapIndex++;
      currentGapIndex++;
      return gap;
    } else if (gapIndex === currentGapIndex) {
      currentGapIndex++;
      return `<span class='vocabularyCard__gap vocabularyCard__gap--active'></span>`;
    }
    return `<span ref='gap' class='vocabularyCard__gap'></span>`;
  });
}

export function getAmountOfCompletedGaps(question) {
  if (question.gaps) {
    return question.gaps.filter(gap => gap.completed === true).length;
  }

  return 0;
}

export function getAmountOfCompletedQuestions(questions) {
  return questions.filter(question => question.completed === true).length;
}

export function getQuestionType(question) {
  return question.type === "quiz_question" ? "quiz_question" : "collocation";
}

function formatQuestionAnswers({ answers, correctAnswer, completed = false }) {
  return answers
    .map(answer => {
      return {
        text: answer,
        selected: completed && correctAnswer === answer,
        correct: (completed && correctAnswer === answer) || false,
        disabled: completed || false
      };
    })
    .sort((answer1, answer2) => (answer1.text > answer2.text ? 1 : -1));
}
