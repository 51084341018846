<template>
  <div class="head">
    <div class="row columns-14 head-lesson">
      <div class="head-lesson__texts">
        <div class="head-lesson__type">{{ $t("lesson") }}</div>
        <div class="h1 head-lesson__title">{{ this.lesson.title }}</div>
      </div>
      <div class="head-lesson__timeline">
        <Timeline></Timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState("lessonStore", ["lesson"])
  }
};
</script>
