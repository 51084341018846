import Vue from "vue";
import HoneybadgerVue from "@honeybadger-io/vue";

function honeyBadger() {
  const config = {
    apiKey: process.env.VUE_APP_HONEYBADGER_API_KEY,
    environment: process.env.VUE_APP_ENV,
    reportData: true
  };

  switch (process.env.VUE_APP_ENV) {
    case "testing":
    case "release":
    case "staging":
    case "production":
      Vue.use(HoneybadgerVue, config);
      break;
  }
}

export default honeyBadger;
