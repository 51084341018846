import client from "../../helpers/client";
import router from "../../router";
import * as lessonPlugin from "./plugins/lessonStorage";

const lessonStore = {
  namespaced: true,

  state: () => {
    return {
      lesson: null,
      availableModules: [],
      currentModule: null,
      cancelRequest: null,
      lessonFinished: false,
      wordTranslation: "",
      lessonLoaded: false,
      demoLessonLevel: null,
      lessonNavVisible: false
    };
  },
  mutations: {
    setLesson(state, { lesson }) {
      state.lesson = lesson;
    },
    setAvailableModules(state, { availableModules }) {
      state.availableModules = availableModules;
    },
    setCurrentModule(state, { currentModule }) {
      state.currentModule = currentModule;
    },
    setCancelRequest(state, request) {
      state.cancelRequest = request;
    },
    setLessonFinished(state) {
      state.lesson.attempt.status = "completed";
      state.lessonFinished = true;
    },
    setWordTranslation(state, { translation }) {
      state.wordTranslation = translation;
    },
    setLessonLoaded(state, { loaded }) {
      state.lessonLoaded = loaded;
    },
    setDemoLessonLevel(state, { level }) {
      state.demoLessonLevel = level;
    },
    isLessonCurrentPath(state, { lessonID, path, router }) {
      if (!lessonID) {
        return;
      }

      const isLessonInCurrentPath = path.lessons.find(item => item.id === parseInt(lessonID));
      if (!isLessonInCurrentPath) {
        return router.replace({ name: "Home" });
      }
    },
    setLessonNavVisibility(state, status) {
      state.lessonNavVisible = status;
    }
  },
  actions: {
    redirectToLesson({ state }, { module }) {
      return router.push({
        name: "Lesson",
        params: {
          lessonId: state.lesson.id,
          attemptId: state.lesson.attempt.uuid,
          module
        }
      });
    },
    async getLesson({ commit }, { lessonId, attemptId }) {
      const {
        data: { data }
      } = await client.get(`api/lessons/${lessonId}/attempt/${attemptId}`);
      commit("setLesson", { lesson: data });
    },
    async getAvailableModules({ state, commit }) {
      const {
        data: { data }
      } = await client.get(`/api/lessons/${state.lesson.id}/modules`);
      commit("setAvailableModules", { availableModules: data });
    },

    async newPathAttempt(context, { path }) {
      const newPathAttempt = await client.post(`api/paths/${path.id}/start`);

      return newPathAttempt.data.data.uuid;
    },

    async newLesson({ commit, state, dispatch }, { lesson, pathAttemptUuid }) {
      commit("setLessonNavVisibility", false);
      const [newLesson] = await Promise.all([
        client.post(`api/lessons/${lesson.id}/attempt/start`, {
          lesson: lesson.id,
          pathAttemptUuid
        })
      ]);
      lesson.attempt.uuid = newLesson.data.data.uuid;
      commit("setLesson", { lesson });
      await dispatch("getAvailableModules");
      await dispatch("redirectToLesson", {
        module: state.availableModules[0]
      });
    },

    async resumeLesson({ commit, state, dispatch }, { lesson }) {
      await client.post(`api/lessons/${lesson.id}/attempt/${lesson.attempt.uuid}/resume`, {
        lesson: lesson.id,
        attempt: lesson.attempt.uuid
      });

      commit("setLesson", { lesson });

      if (!lesson.attempt.currentModule) {
        await dispatch("redirectToLesson", {
          module: state.availableModules[0]
        });
      } else {
        await dispatch("redirectToLesson", {
          module: lesson.attempt.currentModule
        });
      }
    },

    async continueLesson({ dispatch, commit }, { lesson }) {
      lesson.attempt.currentModule = "summary";
      commit("setLessonNavVisibility", false);
      await dispatch("resumeLesson", { lesson });
    },

    async moduleChanged({ state, commit }, { module }) {
      commit("setLessonNavVisibility", false);
      commit("setCurrentModule", { currentModule: module });
      return await client.post(`/api/lessons/${state.lesson.id}/attempt/${state.lesson.attempt.uuid}/change-module`, {
        module
      });
    },

    async finishLesson({ state, getters, commit, rootState }) {
      commit("setCurrentModule", { currentModule: "summary" });
      if (!getters.lessonFinished && !rootState.userStore.demo) {
        commit("setLessonFinished");
        return await client.post(`/api/lessons/${state.lesson.id}/attempt/${state.lesson.attempt.uuid}/finish`);
      }
    },

    async changeLessonModule({ state, dispatch, commit }, { module }) {
      commit("setLessonNavVisibility", false);
      if (state.currentModule !== module) {
        await dispatch("moduleChanged", { module });
        await lessonPlugin.changeRoute(module);
      }
    },

    async goToSummary({ state, dispatch, commit }) {
      if (state.currentModule !== "summary") {
        commit("setLessonNavVisibility", false);
        await dispatch("finishLesson");
        await lessonPlugin.changeRoute("summary");
      }
    },

    async goToNextLesson({ rootState, dispatch, getters }) {
      const lessons = rootState.pathsStore.currentPath.lessons;
      const lessonIndex = lessons.findIndex(item => item.id === getters.currentLessonID);
      const nextLesson = lessons[lessonIndex + 1];

      if (nextLesson.attempt?.status === "finished") {
        await dispatch("continueLesson", { lesson: nextLesson });
        return;
      } else if (nextLesson.attempt?.status) {
        await dispatch("resumeLesson", { lesson: nextLesson });
        return;
      }
      await dispatch("newLesson", {
        lesson: nextLesson,
        pathAttemptUuid: rootState.pathsStore.currentPath.attempt.uuid
      });
    },

    goToNextModule: {
      root: true,
      async handler({ getters, dispatch, commit }) {
        commit("setLessonNavVisibility", false);
        if (getters.nextModule.text === "Next lesson") {
          commit("setLessonLoaded", { loaded: false });
          await dispatch("goToNextLesson");
          return;
        }

        lessonPlugin.changeRoute(getters.nextModule.route);

        if (getters.nextModule.route.params?.module === "summary") {
          dispatch("finishLesson");
        } else if (getters.nextModule.route.params?.module) {
          dispatch("moduleChanged", {
            module: getters.nextModule.route.params.module
          });
        }
      }
    },
    goToPreviousModule: {
      root: true,
      async handler({ getters, dispatch, commit }) {
        commit("setLessonNavVisibility", false);
        lessonPlugin.changeRoute(getters.prevModule.route);

        if (getters.prevModule.route.name !== "Home") {
          await dispatch("moduleChanged", {
            module: getters.prevModule.route.params.module
          });
        }
      }
    }
  },

  getters: {
    prevModule(state) {
      return lessonPlugin.getModule(state.availableModules, state.currentModule, true);
    },
    nextModule(state, getters) {
      return lessonPlugin.getModule(state.availableModules, state.currentModule, false, getters.isLastLesson);
    },
    isLastLesson(state, getters, rootState, rootGetters) {
      return getters.currentLessonIndex === rootGetters["pathsStore/amountOfLessons"] - 1;
    },
    lessonFinished(state) {
      return state.lesson.attempt.status === "completed";
    },
    currentLessonIndex(state, getters, rootState) {
      return rootState.pathsStore.currentPath.lessons.findIndex(item => item.id === state.lesson.id);
    },
    currentLessonID(state) {
      return state.lesson.id;
    },
    lessonNavVisible(state) {
      return state.lessonNavVisible;
    }
  }
};

export default lessonStore;
