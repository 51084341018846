import client from "../../helpers/client";
import * as vocabularyPlugins from "./plugins/vocabularyStorage";
import * as storePlugins from "../plugins/storeStorage";
import axios from "axios";

const vocabularyStore = {
  namespaced: true,

  state: () => ({
    vocabularyQuestions: null,
    questionIndex: 0,
    gapIndex: 0,
    answerText: null,
    timerId: 0,
    correctAnswerAudio: new Audio(require("../../assets/audio/right-answer.mp3")),
    wrongAnswerAudio: new Audio(require("../../assets/audio/wrong-answer.mp3"))
  }),

  getters: {
    currentQuestion: state => {
      return state.vocabularyQuestions[state.questionIndex];
    },
    currentGap: (state, getters) => {
      return getters.currentQuestion.gaps?.[state.gapIndex];
    },

    currentGapCorrectAnswer: (state, getters) => {
      return getters.currentGap?.correctAnswer;
    },

    gapsLength: state => {
      return state.vocabularyQuestions[state.questionIndex].gaps?.length;
    },

    currentAnswers(state, getters) {
      return vocabularyPlugins.getAnswers(getters.currentGap ?? getters.currentQuestion);
    },

    questionIsCompleted(state, getters) {
      return getters.currentQuestion.completed;
    },

    parsedQuestion(state, getters) {
      return vocabularyPlugins.parseQuestion(getters.currentQuestion, state.gapIndex, getters.completedGapsLength);
    },

    completedQuestionsLength(state) {
      return vocabularyPlugins.getAmountOfCompletedQuestions(state.vocabularyQuestions);
    },

    completedGapsLength(state, getters) {
      return vocabularyPlugins.getAmountOfCompletedGaps(getters.currentQuestion);
    }
  },
  mutations: {
    addVocabularyQuestions(state, { formatedQuestions }) {
      state.vocabularyQuestions = formatedQuestions;
    },

    setGapIndex(state, { gapIndex }) {
      state.gapIndex = gapIndex;
    },

    setQuestionIndex(state, { questionIndex }) {
      state.questionIndex = questionIndex;
    },
    setCurrentQuestionCompleted(state) {
      state.vocabularyQuestions[state.questionIndex].completed = true;
    },
    setCurrentGapCompleted(state) {
      state.vocabularyQuestions[state.questionIndex].gaps[state.gapIndex].completed = true;
    },
    setTimerId(state, { timer }) {
      state.timerId = timer;
    }
  },
  actions: {
    checkAnswer({ state, commit, getters, dispatch }, { answerText }) {
      if (answerText === getters.currentQuestion.correctAnswer) {
        commit("setCurrentQuestionCompleted");
        dispatch("postAnswers", { answerText, isCorrect: true });
        return true;
      }

      if (state.gapIndex === getters.gapsLength - 1 && answerText === getters.currentGapCorrectAnswer) {
        commit("setCurrentGapCompleted");
        commit("setCurrentQuestionCompleted");
        dispatch("postAnswers", { answerText, isCorrect: true });
        return true;
      }

      if (answerText === getters.currentGapCorrectAnswer) {
        commit("setCurrentGapCompleted");
        dispatch("getNextQuestion");
        return true;
      }

      dispatch("postAnswers", { answerText, isCorrect: false });
      return false;
    },

    getNextQuestion({ state, commit, getters, dispatch }) {
      state.correctAnswerAudio.pause();
      state.wrongAnswerAudio.pause();
      if (state.timerId) {
        clearTimeout(state.timerId);
        commit("setTimerId", { timer: 0 });
      }

      if (getters.currentGap && state.gapIndex < getters.currentQuestion.gaps.length - 1) {
        commit("setGapIndex", { gapIndex: state.gapIndex + 1 });
      } else if (state.questionIndex < state.vocabularyQuestions.length - 1) {
        commit("setQuestionIndex", { questionIndex: state.questionIndex + 1 });
        commit("setGapIndex", {
          gapIndex: getters.completedGapsLength ? getters.completedGapsLength - 1 : getters.completedGapsLength
        });
      } else {
        dispatch("goToNextModule", null, { root: true });
      }
    },

    getPreviousQuestion({ state, commit, getters, dispatch }) {
      state.correctAnswerAudio.pause();
      state.wrongAnswerAudio.pause();
      if (state.timerId) {
        clearTimeout(state.timerId);
        commit("setTimerId", { timer: 0 });
      }

      if (state.questionIndex > 0) {
        commit("setQuestionIndex", { questionIndex: state.questionIndex - 1 });
        commit("setGapIndex", { gapIndex: getters.gapsLength - 1 || 0 });
      } else {
        dispatch("goToPreviousModule", null, { root: true });
      }
    },

    async getQuestions({ state, commit, getters, rootState }) {
      const { lessonId, attemptId } = storePlugins.getRouteParams();
      rootState.lessonStore.cancelRequest?.cancel();
      commit("lessonStore/setCancelRequest", axios.CancelToken.source(), {
        root: true
      });

      const {
        data: { data }
      } = await client.get(`/api/lessons/${lessonId}/attempt/${attemptId}/vocabulary`, {
        cancelToken: rootState.lessonStore.cancelRequest?.token
      });

      const formatedQuestions = vocabularyPlugins.formatVocabularyQuestions(data);

      commit("addVocabularyQuestions", { formatedQuestions });

      commit("setQuestionIndex", {
        questionIndex:
          getters.completedQuestionsLength === state.vocabularyQuestions.length
            ? getters.completedQuestionsLength - 1
            : getters.completedQuestionsLength
      });
      commit("setGapIndex", { gapIndex: 0 });
    },

    async postAnswers({ getters, rootState, commit }, { answerText, isCorrect }) {
      const { lessonId, attemptId } = storePlugins.getRouteParams();

      rootState.lessonStore.cancelRequest?.cancel();
      commit("lessonStore/setCancelRequest", axios.CancelToken.source(), {
        root: true
      });

      await client.post(
        `/api/lessons/${lessonId}/attempt/${attemptId}/answer-question`,
        {
          questionUuid: getters.currentQuestion.uuid,
          answer: answerText,
          isCorrect: isCorrect
        },
        {
          cancelToken: rootState.lessonStore.cancelRequest?.token
        }
      );
    }
  }
};

export default vocabularyStore;
