import router from "../../router";

export function getRouteParams() {
  const { lessonId, attemptId } = router.app.$route.params;

  return {
    lessonId,
    attemptId
  };
}
