<template>
  <div class="speaking-chat__item">
    <player
      :audio-files="[currentQuestion.audioCorrectAnswer.audio]"
      @endPlaylist="setRecordingIsListened({ listened: true })"
      disable-time-line
      ref="audio-player"
      :class="{ 'audioPlayer--disabled': isDisabled }"
    />
    <split-component
      :text="textWithGaps"
      :class="{ 'speaking-chat__item--blurred': !showAnswerHint }"
    ></split-component>
    <SpeakingHintToggle type="answer" :disabled="isDisabled" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SpeakingHintToggle from "./SpeakingHintToggle.vue";

export default {
  name: "SpeakingAnswer2",
  components: { SpeakingHintToggle },
  methods: {
    ...mapMutations("speakingStore", ["setRecordingIsListened"])
  },
  computed: {
    isDisabled() {
      return !this.recordingIsListened && !this.showQuestionHint && !this.isCompleted;
    },
    textWithGaps() {
      let text = "";
      this.currentQuestion.answerWithGaps.map(item => {
        const textItem = item.isGap ? `<span class="blurred-gap">${item.words}</span>` : item.words;
        text += textItem;
      });
      return text;
    },
    ...mapState("speakingStore", ["showAnswerHint", "recordingIsListened", "showQuestionHint"]),
    ...mapGetters("speakingStore", ["currentQuestion", "isCompleted"])
  }
};
</script>
