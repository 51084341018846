<template>
  <div class="primary-button answer">
    <button
      class="round-button answer__button"
      :class="{ wrong: !correct && selected, disabled: disabled, correct: correct }"
      @click="answerClick"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AnswerButton",
  props: {
    text: {
      type: String,
      required: true
    },
    disabled: Boolean,
    correct: Boolean,
    selected: Boolean,
    index: Number
  },

  methods: {
    answerClick() {
      if (this.selected) {
        return;
      }

      this.checkAnswer({ answerText: this.text }).then(isCorrect => {
        this.$emit("answerClick", { index: this.index, isCorrect });
      });
    },

    ...mapActions("vocabularyStore", ["checkAnswer"])
  }
};
</script>
