import Vue from "vue";
import VueRouter from "vue-router";
import Toast from "vue-toastification";
import toastConfig from "./ToastConfig";
import { InlineSvgPlugin } from "vue-inline-svg";
import Transitions from "vue2-transitions";
import vuescroll from "vuescroll";
import "@/validation";
import { Plugin } from "vue-fragment";
import VModal from "vue-js-modal";
import VueMeta from "vue-meta";
import vueVimeoPlayer from "vue-vimeo-player";
import VueYoutube from "vue-youtube";
import VueClickOutsideElement from "vue-click-outside-element";
import VueSocialSharing from "vue-social-sharing";
import Portal from "portal-vue";
import PortalTarget from "portal-vue";
import FloatingVue from "floating-vue";
import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

function VuePlugins() {
  Vue.use(VueRouter);
  Vue.use(Toast, toastConfig);
  Vue.use(Transitions);
  Vue.use(InlineSvgPlugin);
  Vue.use(vuescroll, {
    ops: {
      rail: {
        background: "#CFCFCF",
        opacity: 0.3,
        size: "10px",
        specifyBorderRadius: "5px",
        gutterOfEnds: "15px",
        gutterOfSide: "2vw",
        keepShow: false
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: false,
        keepShow: true,
        background: "#89DFE6",
        opacity: 1,
        hoverStyle: false,
        specifyBorderRadius: "5px",
        minSize: 0,
        size: "10px",
        disable: false
      }
    }
  });
  Vue.use(Plugin);
  Vue.use(VModal);
  Vue.use(VueMeta);
  Vue.use(vueVimeoPlayer);
  Vue.use(VueYoutube);
  Vue.use(VueClickOutsideElement);
  Vue.use(VueSocialSharing);
  Vue.use(Portal);
  Vue.use(PortalTarget);
  Vue.use(FloatingVue);
  Vue.directive("tooltip", VTooltip);
}

export default VuePlugins;
