import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import FormMixin from "./mixins/FormMixin";

import i18n from "./i18n";
import GlobalComponents from "./helpers/GlobalComponents";
import honeyBadger from "./plugins/HoneyBadger";
import VuePlugins from "./plugins/Plugins";
import store from "./store/stores";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false;

VuePlugins();
honeyBadger();
GlobalComponents();

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY || "https://8aa020892de74c848e634ac439d92b79@o1336242.ingest.sentry.io/6701892",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "vivolang.develop.deravesoftware.com",
        "app.vivolang.develop.deravesoftware.com",
        "nova.vivolang.com",
        "app.vivolang.com",
        /^\//
      ]
    })
  ],
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_ENV,
  trackComponents: true,
  attachProps: true,
  logErrors: true,
  hooks: ["activate", "create", "destroy", "mount", "unmount", "update"]
});

new Vue({
  render: h => h(App),
  router,
  i18n,
  store,
  mixins: [FormMixin]
}).$mount("#app");
