<template>
  <div class="popup popup__path-change">
    <div class="h2 popup__title">
      {{ header }}
    </div>
    <div class="popup__desc">
      {{ description }}
    </div>
    <div class="popup__select">
      <v-select label="title" :value="currentPath.title" :options="getPaths" @input="setPath" :clearable="false" />
    </div>
    <div class="popup__buttons--welcome">
      <div class="popup__item">
        <InputButton :text="$t('change-path.button')" action="button" @click="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapState, mapActions } from "vuex";

export default {
  name: "ChangePathPopup",
  props: {
    header: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    getPaths() {
      return this.paths.filter(path => path.id !== this.currentPath.id);
    },
    ...mapState("pathsStore", ["currentPath", "paths"])
  },
  methods: {
    async setPath(path) {
      this.$emit("close");
      await this.getPathLessons({ pathId: path.id });
      await this.sendCurrentPathId({ currentPathId: path.id });

      if (this.$route.name !== "Home") {
        await this.$router.replace({ name: "Home" });
      }
    },

    ...mapActions("pathsStore", ["getPathLessons"]),
    ...mapActions("userStore", ["sendCurrentPathId"])
  },
  components: { vSelect }
};
</script>
