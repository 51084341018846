import VueRouter from "vue-router";
import Vue from "vue";
import PKCE from "js-pkce";
import store from "@/store/stores";

Vue.prototype.$pkce = new PKCE({
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_FRONTEND_URL + "/auth",
  authorization_endpoint: process.env.VUE_APP_AUTH_URL + "/oauth/authorize",
  token_endpoint: process.env.VUE_APP_AUTH_URL + "/oauth/token",
  requested_scopes: "*"
});

const routes = [
  {
    path: "/terms-and-conditions",
    name: "Terms and conditions",
    meta: {
      termsPage: true
    },
    component: () => import(/* webpackChunkName: "termsAndConditions" */ "../views/TermsAndConditions.vue")
  },
  {
    path: "/dashboard",
    alias: "/",
    name: "Home",
    meta: {},
    component: () => import(/* webpackChunkName: "Main" */ "../views/Dashboard.vue")
  },
  {
    path: "/lessons/:lessonId/attempt/:attemptId/:module",
    name: "Lesson",
    props: {
      lessonName: true
    },
    component: () => import(/* webpackChunkName: "Lesson" */ "../views/Lesson.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {},
    component: () => import(/* webpackChunkName: "Settings" */ "../views/Settings.vue")
  },
  {
    path: "/start-demo-lesson",
    name: "StartDemoLesson",
    meta: {
      loginPage: true
    },
    component: () => import(/* webpackChunkName: "StartDemo" */ "../views/StartDemo.vue")
  },
  {
    path: "/auth",
    name: "Auth",
    meta: {
      loginPage: true
    },
    component: () => import(/* webpackChunkName: "Auth" */ "../views/Auth.vue")
  },
  {
    path: "/login-sso",
    name: "AuthSSO",
    meta: {
      loginPage: true
    },
    component: () => import(/* webpackChunkName: "Auth-SSO" */ "../views/Auth-SSO.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.termsPage) {
    return next();
  }

  if (to.name === "StartDemoLesson") {
    window.localStorage.removeItem("token");
    store.commit("userStore/setTokens", null);
  }

  const lcTokens = JSON.parse(window.localStorage.getItem("token"));
  const token = store.state.userStore.tokens || lcTokens;

  if (lcTokens && !store.state.userStore.tokens) {
    store.commit("userStore/setTokens", lcTokens);
  }

  if (!to.meta.loginPage && !token) {
    return window.location.replace(router.app.$pkce.authorizeUrl()); //TODO: by clicking logout token is still valid?
  }

  return next();
});

export default router;
