<template>
  <div class="podcast lesson-module" @click="isIntroJs && introJs.podcast ? $intro().exit() : ''" v-if="podcast">
    <Portal to="lesson-description">
      {{ videoUrl ? $t("podcast.desc-video") : $t("podcast.desc") }}
    </Portal>
    <div class="podcast__wrapper">
      <div class="podcast__resources-wrapper" :class="['is-' + activeResourceTab]">
        <div class="podcast__tab-btn-wrapper podcast__tab-btn-wrapper--desktop">
          <template v-for="(resource, index) in resources">
            <PodcastTab
              :data="resource"
              :key="index"
              :disabled="{ video: !videoUrl, audio: !getArrayOfAudio, image: !lesson.podcastImage }"
              :active="activeResourceTab === resource"
              @tabChanged="changeActiveResourceTab(resource)"
            />
          </template>
        </div>
        <Portal to="module-additional-bar">
          <div class="podcast__tab-btn-wrapper podcast__tab-btn-wrapper--mobile">
            <template v-for="(resource, index) in resources">
              <PodcastTab
                :data="resource"
                :key="index"
                :disabled="{ video: !videoUrl, audio: !getArrayOfAudio, image: !lesson.podcastImage }"
                :active="activeResourceTab === resource"
                @tabChanged="changeActiveResourceTab(resource)"
              />
            </template>
          </div>
        </Portal>
        <div class="podcast__tab-content-wrapper">
          <div class="podcast__tab-content-item" v-if="activeResourceTab === Resource.Video">
            <div class="podcast__player is-video-player" :class="{ 'podcast__player--video': videoUrl }">
              <video-player
                :style="isMobileView ? { transform: 'translateY(-' + upPosition + 'px)', top: topHeight + 'px' } : {}"
                :class="{ 'is-mobile': isMobileView }"
                v-if="videoUrl"
                :url="videoUrl"
                @playerLoaded="onPlayerLoaded"
              />
            </div>
          </div>
          <div class="podcast__tab-content-item" v-if="activeResourceTab === Resource.Audio">
            <div class="podcast__player podcast__player--desktop">
              <Player
                :stop-button="true"
                :audio-files="getArrayOfAudio"
                @changeSong="songChanged"
                @playerLoaded="onPlayerLoaded"
              />
            </div>
          </div>
          <Portal to="mobile-nav-slot">
            <div class="podcast__tab-content-item" v-if="activeResourceTab === Resource.Audio">
              <div class="podcast__player podcast__player--mobile">
                <Player
                  :stop-button="true"
                  :audio-files="getArrayOfAudio"
                  @changeSong="songChanged"
                  @playerLoaded="onPlayerLoaded"
                />
              </div>
            </div>
          </Portal>
          <div class="podcast__tab-content-item" v-if="activeResourceTab === Resource.Image">
            <img class="podcast__image" v-if="lesson.podcastImage" :src="lesson.podcastImage" alt="podcast image" />
          </div>
        </div>
      </div>
      <div
        class="podcast__texts-wrapper"
        :class="{ 'is-smaller': videoUrl }"
        :style="isMobileView && activeResourceTab === Resource.Video ? { 'margin-top': height + 'px' } : {}"
      >
        <div class="podcast__texts" :class="{ 'podcast__texts--video': videoUrl }" v-if="!isMobileView">
          <vue-scroll
            :disable="true"
            :enable="false"
            ref="vs"
            :initialScrollY="0"
            :ops="{
              scrollPanel: {
                scrollingX: false,
                scrollingY: true
              },
              vuescroll: {
                detectResize: true,
                sizeStrategy: 'percent'
              },
              rail: { gutterOfSide: '0' }
            }"
            @handle-scroll="getLastVisibleRows"
          >
            <p
              class="podcast__row"
              :class="{ 'podcast__row--last-visible': isRowVisible(`podcast-line-${index}`) }"
              :id="`podcast-line-${index}`"
              v-for="(text, index) in getArrayOfTexts"
              :key="index"
            >
              <span
                :class="{ 'is-current': activeResourceTab && activeResourceTab !== Resource.Video && isCurrent(index) }"
                class="podcast__sentence"
              >
                <split-component class="podcast__sentence" :text="text"></split-component>
              </span>
            </p>
          </vue-scroll>

          <div style="height: 50px"></div>
        </div>
        <div class="podcast__texts" :class="{ 'podcast__texts--video': videoUrl }" v-else>
          <p
            class="podcast__row"
            :class="{ 'podcast__row--last-visible': isRowVisible(`podcast-line-${index}`) }"
            :id="`podcast-line-${index}`"
            v-for="(text, index) in getArrayOfTexts"
            :key="index"
          >
            <span
              :class="{ 'is-current': activeResourceTab && activeResourceTab !== Resource.Video && isCurrent(index) }"
              class="podcast__sentence"
            >
              <split-component class="podcast__sentence" :text="text"></split-component>
            </span>
          </p>
        </div>
      </div>
    </div>
    <Portal to="module-progress">
      <ProgressBar :currentProgress="progress" :totalProgressAmount="100" :paginationDisplayed="false" />
    </Portal>
  </div>
  <Loading v-else></Loading>
</template>

<script>
import client from "../../helpers/client";
import _ from "lodash";
import introJsMixin from "@/mixins/IntroJsMixin";
import { mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";

import VideoPlayer from "../VideoPlayer";
import PodcastTab from "../podcast/PodcastTab.vue";
import ProgressBar from "../ProgressBar";
import Resource from "../../enums/resource";
import { useScrollComposition } from "../../mixins/ScrollComopable";
import { defineComponent } from "@vue/composition-api";
import { useMediaQuery } from "@vueuse/core";
import LanguageChangeMixin from "../../mixins/LanguageChangeMixin";

export default defineComponent({
  name: "Podcast",
  components: { PodcastTab, VideoPlayer, ProgressBar },
  data() {
    return {
      podcast: null,
      videoUrl: null,
      currentSongIndex: 0,
      videoLoaded: null,
      lastVisibleRows: null,
      activeResourceTab: null,
      resources: [Resource.Video, Resource.Audio, Resource.Image]
    };
  },
  mixins: [introJsMixin, LanguageChangeMixin],
  async mounted() {
    await this.getPodcast();
    await this.getVideoUrl().then(() => {
      this.videoLoaded = true;
    });
    this.getLastVisibleRows();
    this.getActiveResource();
    this.defineHelpTours();
  },
  props: {
    header: {
      required: true
    }
  },
  computed: {
    isMobileView() {
      return useMediaQuery("(max-width: 479px)").value;
    },
    Resource() {
      return Resource;
    },
    getArrayOfAudio() {
      return _.map(this.podcast, "audio");
    },

    getArrayOfTexts() {
      return _.map(this.podcast, "htmlText");
    },

    ...mapState("lessonStore", ["availableModules", "lesson"]),
    ...mapState("userStore", ["introJs", "userLanguage"]),
    ...mapGetters("userStore", ["isIntroJs"])
  },

  watch: {
    podcast() {
      this.$emit("lessonModuleLoaded");
    }
  },

  methods: {
    validateUrl(videoUrl) {
      try {
        const url = new URL(videoUrl);
        this.videoUrl = url.host.includes("vimeo") || url.host.includes("youtube") ? url.href : null;
      } catch (err) {
        this.videoUrl = null;
      }
    },

    async getPodcast() {
      const { lessonId, attemptId } = this.$route.params;
      this.cancelRequest?.cancel();
      this.setCancelRequest(axios.CancelToken.source());

      const {
        data: { data: podcast }
      } = await client.get(`/api/lessons/${lessonId}/attempt/${attemptId}/podcast`, {
        cancelToken: this.cancelRequest?.token
      });

      this.podcast = podcast;
    },

    async getVideoUrl() {
      const { lessonId, attemptId } = this.$route.params;
      this.cancelRequest?.cancel();
      this.setCancelRequest(axios.CancelToken.source());

      const {
        data: {
          data: { videoUrl }
        }
      } = await client.get(`api/lessons/${lessonId}/attempt/${attemptId}`, {
        cancelToken: this.cancelRequest?.token
      });

      this.validateUrl(videoUrl);
    },

    songChanged(songIndex) {
      this.currentSongIndex = songIndex;

      if (document.querySelector(`#podcast-line-${songIndex}`)) {
        this.$refs["vs"].scrollIntoView(`#podcast-line-${songIndex}`, 300);
      }
    },

    isCurrent(index) {
      return index === this.currentSongIndex;
    },

    getLastVisibleRows() {
      const visibleRows = this.$refs["vs"].getCurrentviewDom();
      this.lastVisibleRows = visibleRows.slice(-2);
    },

    isRowVisible(row) {
      const result = this.lastVisibleRows?.filter(item => item.id === row);
      return result && result.length > 0;
    },

    onPlayerLoaded() {
      this.$nextTick(() => {
        this.showIntroFirstTime("podcast");
      });
    },

    defineHelpTours() {
      const tours = [
        {
          key: "podcast",
          data: {
            steps: [
              {
                title: "1/2",
                element: document.querySelector(".center-container"),
                intro: this.$t("podcast.intro"),
                position: "top"
              },
              {
                title: "2/2",
                element: document.querySelector(".podcast__texts"),
                intro: this.$t("podcast.sentences")
              }
            ]
          }
        }
      ];

      this.setTours(tours);
    },

    getActiveResource() {
      const activeResources = this.resources.map(item => {
        if (
          (item === Resource.Video && this.videoUrl) ||
          (item === Resource.Audio && this.getArrayOfAudio) ||
          (item === Resource.Image && this.lesson.podcastImage)
        ) {
          return {
            name: item,
            active: true
          };
        } else {
          return {
            name: item,
            active: false
          };
        }
      });
      this.activeResourceTab = activeResources.filter(resource => resource.active)[0].name;
    },

    changeActiveResourceTab(resource) {
      this.activeResourceTab = resource;
    },

    ...mapMutations("lessonStore", ["setCancelRequest"]),
    ...mapMutations("userStore", ["setIntroJs", "setTours"])
  },

  beforeDestroy() {
    this.cancelRequest?.cancel();
  },
  setup(props) {
    const { upPosition, y, height, progress, topHeight } = useScrollComposition(props.header);

    return {
      upPosition,
      y,
      height,
      progress,
      topHeight
    };
  }
});
</script>
