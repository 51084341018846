<template>
  <div class="summary lesson-module" v-if="!loading && !loadingStats">
    <div class="lesson-module__desc summary-desc__container">
      <div class="summary-desc__description">
        {{ demo ? $t("congratulations") : $t("summary.desc") }}
        <span v-if="!demo" class="summary-desc__description--lessonTitle">{{ lesson.title }}</span>
      </div>
      <span class="summary-desc__description--small">{{
        demo ? $t("you-have-just-improved-your-business-english-language-skills!") : $t("summary.results")
      }}</span>
    </div>
    <div class="summary__container">
      <div class="summary__stats" ref="stats">
        <div class="summary__circle" ref="circle">
          <vue-circle
            :progress="stats.overallProgress"
            :size="244"
            :reverse="false"
            line-cap="round"
            :fill="{ color: '#4E9398' }"
            empty-fill="#DBE9EA"
            :start-angle="-Math.PI / 2"
            insert-mode="append"
            :thickness="50"
            :show-percent="true"
          >
          </vue-circle>
        </div>
        <div class="summary-modules-stats__container">
          <div v-if="demo" class="summary-modules-stats__demo-stats" ref="demoStats">
            <div class="summary-demo-stats__item">
              {{ $t("lesson") }}: <span class="summary-demo-stats__item-value">{{ lesson.title }}</span>
            </div>
            <div class="summary-demo-stats__item">
              {{ $t("difficulty") }}: <span class="summary-demo-stats__item-value">{{ $t(lesson.difficulty) }} </span>
            </div>
            <div class="summary-demo-stats__item">
              {{ $t("new-words") }}: <span class="summary-demo-stats__item-value">{{ stats.answeredQuestions }}</span>
            </div>
          </div>
          <div class="summary-modules-stats__bars" ref="progressBars">
            <div
              class="summary-progressbar__container"
              :class="{ 'summary-progressbar__container--disabled': !availableModules.includes('vocabulary') }"
            >
              <div class="summary-progressbar__labels">
                <span>{{ $t("Vocabulary") }}</span>
                <span>{{ stats.practiceProgress }}%</span>
              </div>
              <div class="summary-progressbar__bar">
                <transition @appear="enter($event, stats.practiceProgress)">
                  <div class="summary-progressbar__bar--progress" />
                </transition>
              </div>
            </div>
            <div
              class="summary-progressbar__container"
              :class="{ 'summary-progressbar__container--disabled': !availableModules.includes('speaking') }"
            >
              <div class="summary-progressbar__labels">
                <span>{{ $t("Speaking") }}</span>
                <span>{{ stats.speakingProgress }}%</span>
              </div>
              <div class="summary-progressbar__bar">
                <transition @appear="enter($event, stats.speakingProgress)">
                  <div class="summary-progressbar__bar--progress" />
                </transition>
              </div>
            </div>
          </div>
          <div v-if="!demo" class="summary-icons__container">
            <div
              class="summary-icons__icon"
              :class="{ 'summary-icons__icon--disabled': !availableModules.includes('vocabulary') }"
            >
              <span
                ><Icon
                  size="small"
                  name="micro"
                  icon-color-name="white-color"
                  background-color-name="secondary-color"
                  type="outline"
              /></span>
              <span class="summary-icons__label">
                {{ stats.answeredQuestions }} {{ $t("summary.questionsAnswered") }}</span
              >
            </div>
            <div class="summary-icons__icon">
              <span
                ><Icon
                  size="small"
                  name="refresh"
                  icon-color-name="white-color"
                  background-color-name="orange-color"
                  type="outline"
              /></span>
              <span class="summary-icons__label">{{ $t("summary.time") }} {{ stats.time }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="demo" class="summary-demo__register" ref="register">
        <div class="summary-desc__description--register">
          {{ $t("demo-register-description") }}
        </div>
        <input-button :text="$t('demo-summary-button')" @click="goToRegister"></input-button>
      </div>
      <div v-else class="summary__buttons">
        <summary-button
          v-for="button in buttons"
          :key="button.name"
          :name="button.name"
          :label="button.label"
          :action="button.action"
          :visible="button.visible"
        />
      </div>
    </div>
  </div>
  <loading v-else />
</template>

<script>
import VueCircle from "vue2-circle-progress";
import SummaryButton from "../summary/SummaryButton";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import client from "../../helpers/client";

export default {
  name: "Summary",
  components: { VueCircle, SummaryButton },
  data() {
    return {
      stats: null,
      loadingStats: true
    };
  },

  props: {
    loading: Boolean
  },

  computed: {
    buttons() {
      return [
        { name: "refresh", label: this.$t("retake-this-lesson"), action: "lesson", visible: true },
        {
          name: "message",
          label: this.$t("redo-practice-quiz"),
          action: "vocabulary",
          visible: this.availableModules.includes("vocabulary")
        },
        {
          name: "micro",
          label: this.$t("redo-speaking"),
          action: "speaking",
          visible: this.availableModules.includes("speaking")
        },
        { name: "home", label: this.$t("back-to-dashboard"), action: "dashboard", visible: true }
      ];
    },
    ...mapState("lessonStore", ["lesson", "cancelRequest", "availableModules"]),
    ...mapState("userStore", ["demo", "introJs", "id"]),
    ...mapState("pathsStore", ["paths", "currentPath"])
  },
  methods: {
    enter(el, width) {
      el.style.width = `${width}%`;
      el.style.transition = `width 0.5s linear`;
    },
    async getSummaryStats() {
      this.cancelRequest?.cancel();
      this.setCancelRequest(axios.CancelToken.source());

      const {
        data: { data }
      } = await client.get(
        `/api/lessons/${this.lesson.id}/attempt/${this.lesson.attempt.uuid}/summary`,
        this.cancelRequest?.token
      );

      this.stats = data;
    },
    async goToRegister() {
      await this.logOutUser({ path: "Registration" });
    },

    ...mapMutations("lessonStore", ["setCancelRequest", "isLessonCurrentPath"]),
    ...mapMutations("userStore", ["setIntroJs"]),
    ...mapActions("pathsStore", ["getAvailablePaths"]),
    ...mapActions("userStore", ["logOutUser"])
  },
  async mounted() {
    await this.getSummaryStats();
    this.loadingStats = false;
    this.$emit("lessonModuleLoaded");
  }
};
</script>
