import { computed } from "@vue/composition-api";
import { useElementSize, useWindowScroll, useWindowSize } from "@vueuse/core";

export const useScrollComposition = header => {
  const { y } = useWindowScroll();
  const { width, height } = useWindowSize();
  const { height: topContainerHeight } = useElementSize(header);

  const heightVideo = computed(() => {
    return (9 / 16) * width.value;
  });

  const upPosition = computed(() => {
    const titleTabsHeight =
      topContainerHeight.value - 18 - document.querySelector(".head-lesson__module-details").clientHeight;
    return y.value > titleTabsHeight ? titleTabsHeight : y.value;
  });

  const progress = computed(() => {
    const ratio = (document.body.clientHeight - height.value) / 100;
    const percentage = y.value / ratio;
    return percentage > topContainerHeight ? 100 : percentage;
  });

  const topHeight = computed(() => {
    return topContainerHeight.value + 55;
  });

  return {
    upPosition,
    y,
    height: heightVideo,
    progress,
    topHeight
  };
};
