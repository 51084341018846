<template>
  <div class="head head--mobile">
    <div class="row columns-14 head-lesson">
      <div class="head-lesson__module-details" v-if="module !== 'summary'">
        <div class="head-lesson__module-info">
          <Icon
            size="small"
            :name="$t(getCurrentModuleInfo().icon)"
            header
            icon-color-name="white-color"
            background-color-name="secondary-color"
            type="outline"
          />
          <div class="head-lesson__module-name">{{ $t(getCurrentModuleInfo().text) }}</div>
        </div>
        <div class="head-lesson__module-progress">
          <p class="progress-description">{{ $t("lesson.progress.desc") }}</p>
          <portal-target name="module-progress"></portal-target>
        </div>
      </div>
      <div class="head-lesson__texts">
        <div class="h1 head-lesson__title">{{ this.lesson.title }}</div>
      </div>
    </div>
    <portal-target name="module-additional-bar"></portal-target>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapModules, modulesList } from "../../store/Lesson/plugins/lessonStorage";

export default {
  name: "HeaderMobile",
  computed: {
    ...mapState("lessonStore", ["lesson", "currentModule"])
  },
  props: {
    module: {
      required: true
    }
  },
  mounted() {
    this.$emit("headerLoaded");
  },
  methods: {
    getCurrentModuleInfo() {
      const moduleIndex = modulesList.indexOf(this.currentModule);
      return mapModules[moduleIndex];
    }
  }
};
</script>
